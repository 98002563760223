import React from 'react'
import PropTypes from 'prop-types'

const MoreIcon = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M19 10a2 2 0 110 4 2 2 0 010-4zm-7 0a2 2 0 110 4 2 2 0 010-4zm-7 0a2 2 0 110 4 2 2 0 010-4z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default MoreIcon

MoreIcon.propTypes = {
  props: PropTypes.string,
}

MoreIcon.defaultProps = {
  props: null,
}
