import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container, Block } from '~/components'
import FeedSidebar from './FeedSidebar'
import Posts from './Posts'

import { Content, FeedContent } from './styles'
import CreatePost from './CreatePost'

function Feed() {
  return (
    <Container>
      <Block flex={0.1} style={{ minHeight: 105 }}>
        <h1>Feed</h1>
      </Block>
      <Content>
        <FeedContent>
          <CreatePost />
          <Posts />
        </FeedContent>
        <FeedSidebar />
      </Content>
    </Container>
  )
}

export default withAnalytics(Feed, {})
