import React from 'react'
import PropTypes from 'prop-types'

import Notifications from '../Notifications'
import Button from '../Button'

import {
  Container,
  Nav,
  AuthNav,
  NavLink,
  NavDrawer,
  NavButtonLink,
  SimpleLink,
} from './styles'

import {
  LogoIcon,
  CloseIcon,
  MenuIcon,
  SettingsIcon,
  AvatarIcon,
} from '~/assets/icons'

const Header = ({ auth }) => {
  const [open, setOpen] = React.useState(false)

  function closeDrawer() {
    setOpen(false)
  }

  function toggleMenu() {
    setOpen(!open)
  }

  // function login() {
  //   window.location.href = 'https://dev.oktave.is'
  // }

  // function register() {
  //   window.location = 'https://dev.oktave.is/register'
  // }

  const Logo = () => (
    <div>
      <NavLink to="/" onClick={closeDrawer}>
        <LogoIcon />
      </NavLink>
    </div>
  )

  const NavLinks = () => (
    <>
      <li>
        <NavLink to="/about">About Us</NavLink>
      </li>
      <li>
        <NavLink to="/pricing">Pricing</NavLink>
      </li>
      <li>
        <NavLink to="/login">Sign In</NavLink>
        {/* <NavLink onClick={login}>Sign In</NavLink> */}
      </li>
      <li>
        <NavButtonLink to="/register">Sign Up</NavButtonLink>
        {/* <NavButtonLink onClick={register}>Sign Up</NavButtonLink> */}
      </li>
    </>
  )

  const AuthNavLinks = () => (
    <>
      <li>
        <Button preset="primary" to="/register">
          Upgrade
        </Button>
      </li>
      <li>
        <Notifications />
      </li>
      <li>
        <Button preset="icon" to="/">
          <SettingsIcon />
        </Button>
      </li>
      <li>
        <Button preset="icon" to="/profile">
          <AvatarIcon size={24} />
        </Button>
      </li>
    </>
  )

  return (
    <>
      <Container auth={auth}>
        {auth ? (
          <AuthNav auth={auth}>
            <AuthNavLinks />
          </AuthNav>
        ) : (
          <>
            <Logo />
            <Nav open={open} auth={auth}>
              <NavLinks />
            </Nav>
            <NavDrawer>
              <SimpleLink onClick={toggleMenu}>
                {open ? <CloseIcon /> : <MenuIcon />}
              </SimpleLink>
            </NavDrawer>
          </>
        )}
      </Container>
    </>
  )
}

export default Header

Header.propTypes = {
  auth: PropTypes.bool,
}

Header.defaultProps = {
  auth: false,
}
