import React from 'react'
import PropTypes from 'prop-types'
import { formatDistance, parseISO } from 'date-fns'

// import api from '~/services/api'

import More from './More'
import PostButtons from './PostButtons'

import { Avatar, Image, Separator } from '~/components'
import { Post, PostTop, PostContent, UserInfo } from './styles'

const PostItem = ({ post }) => {
  const { image, user, content, created_at } = post

  const time = formatDistance(parseISO(created_at), new Date(), {
    addSuffix: true,
  })

  return (
    <>
      <Post>
        <PostTop>
          <Avatar size={60} />
          <UserInfo>
            <p>{user.username}</p>
            <time>{time}</time>
          </UserInfo>
          <More id={post.id} />
        </PostTop>
        <PostContent>
          <p>{content}</p>
          {image && <Image src={image} width={900} height={900} />}
        </PostContent>
        <Separator line margin={20} />
        <PostButtons data={post} />
      </Post>
    </>
  )
}

export default PostItem

PostItem.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    user: PropTypes.shape({
      username: PropTypes.string,
    }),
    content: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
}
