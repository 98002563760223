import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { ModalItem, Background, ModalTop } from './styles'
import Button from '../Button'
import { CloseIcon } from '~/assets/icons'

const Modal = ({ isVisible, toggle, title, children }) => {
  const variants = {
    visible: { opacity: 0.9 },
    hidden: { opacity: 0 },
  }

  const modalVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.2,
        type: 'spring',
        stiffness: 500,
        damping: 15,
        duration: 3,
      },
    },
    hidden: {
      y: 100,
      opacity: 0,
      transition: { duration: 2 },
    },
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <ModalItem
            key="modal"
            initial="hidden"
            exit="hidden"
            animate="visible"
            variants={modalVariants}
          >
            <ModalTop>
              <h4>{title}</h4>
              <Button preset="icon" onClick={toggle}>
                <CloseIcon />
              </Button>
            </ModalTop>
            {children}
          </ModalItem>
          <Background
            key="bg"
            initial="hidden"
            exit="hidden"
            animate="visible"
            variants={variants}
            onClick={toggle}
          />
        </>
      )}
    </AnimatePresence>
  )
}
export default Modal

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
}

Modal.defaultProps = {
  title: '',
}
