import React from 'react'
import PropTypes from 'prop-types'

import { Button, NavLink } from './styles'

const MainButton = ({
  children,
  preset,
  type = 'button',
  selected,
  active,
  to,
  loading,
  style,
  onClick,
}) => {
  return (
    <>
      {to ? (
        <NavLink preset={preset} active={active} selected={selected} to={to}>
          {children}
        </NavLink>
      ) : (
        <Button
          // whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.95 }}
          preset={preset}
          style={style}
          selected={selected}
          active={active}
          type={type}
          onClick={onClick}
        >
          {loading || children}
        </Button>
      )}
    </>
  )
}

export default MainButton

MainButton.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.node,
  preset: PropTypes.string.isRequired,
  type: PropTypes.string,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
}

MainButton.defaultProps = {
  style: null,
  type: 'button',
  selected: false,
  loading: false,
  active: false,
  to: null,
  onClick: null,
}
