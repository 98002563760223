import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import api from '~/services/api'

import {
  getBandsSuccess,
  getBandsFailure,
  createBandFailure,
  createBandSuccess,
  updateBandSuccess,
  updateBandFailure,
} from './actions'

export function* getBands() {
  try {
    const response = yield call(api.get, 'bands')

    yield put(getBandsSuccess(response.data))
  } catch (err) {
    yield put(getBandsFailure())
  }
}

export function* createBand({ payload }) {
  try {
    const { name, email, ...rest } = payload.data

    const band = {
      name,
      email,
      ...(rest.oldPassword ? rest : {}),
    }

    const response = yield call(api.post, 'bands', band)

    toast.success('Band was successfully updated!')

    yield put(createBandSuccess(response.data))
  } catch (err) {
    toast.error('Unable to create Band, please verify your information')

    yield put(createBandFailure())
  }
}

export function* updateBand({ payload }) {
  try {
    const { name, email, ...rest } = payload.data

    const band = {
      name,
      email,
      ...(rest.oldPassword ? rest : {}),
    }

    const response = yield call(api.put, 'bands', band)

    toast.success('Band was successfully updated!')

    yield put(updateBandSuccess(response.data))
  } catch (err) {
    toast.error('Unable to update Band, please verify your information')

    yield put(updateBandFailure())
  }
}

export default all([
  takeLatest('@bands/GET_BANDS_REQUEST', getBands),
  takeLatest('@bands/CREATE_BAND_REQUEST', updateBand),
  takeLatest('@bands/UPDATE_BAND_REQUEST', updateBand),
])
