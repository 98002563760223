import { createGlobalStyle } from 'styled-components'
import { lighten, darken } from 'polished'

import { theme } from './index'

import 'react-perfect-scrollbar/dist/css/styles.css'

export default createGlobalStyle`
  @font-face {
    font-family: ${theme.font.family};
    src: url(${theme.font.regular});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: ${theme.font.family};
    src: url(${theme.font.medium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: ${theme.font.family};
    src: url(${theme.font.bold});
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: ${theme.font.family};
    src: url(${theme.font.extraBold});
    font-weight: 800;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body {
    height: 100%;
    min-height: 100%;
  }

  #root {
    display: flex;
    flex: 1 1 auto;
    flex-basis: content;
    /* flex-flow: 1; */
    /* align-items: stretch; */
    /* flex-direction: column; */
    /* display: block; */
  }

  body {
    background: ${theme.color.dark};
    -webkit-font-smoothing: antialiased !important;
    color: ${theme.color.light};
    text-rendering: optimizeLegibility !important;
  }

  body, input, textarea {
    font-size: calc(${theme.font.body} + 0.2vw);
    font-family: ${
      theme.font.family
    }, -apple-system, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  h1 {
    font-size: calc(${theme.font.h1} + 0.5vw);
  }

  h2 {
    font-size: calc(${theme.font.h2} + 0.4vw);
  }

  h3 {
    font-size: calc(${theme.font.h3} + 0.4vw);
  }

  h4 {
    font-size: calc(${theme.font.h4} + 0.4vw);
  }

  h5 {
    font-size: calc(${theme.font.h5} + 0.5vw);
  }

  button {
    cursor: pointer;
    font-size: calc(14px + 0.2vw);
    font-family: ${theme.font.family};
    border: none;
    font-weight: 500;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  textarea,
  input.text,
  input[type="text"],
  input[type="button"],
  input[type="submit"],
  .input-checkbox {
    -webkit-appearance: none;
  }

  input:required {
    box-shadow: none;
  }

  input:invalid {
    box-shadow: none;
}

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px #222222 inset !important;
      /* font-size: calc(${theme.font.body} + 0.2vw); */
      color: white !important;

  }
  
  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    font-family: ${theme.font.family} !important;
  }

  .react-datepicker-wrapper {
    div {
      input {
        background: none;
        width: 100%;
        border: solid ${theme.color.darkGray} 1px;
        border-radius: 8px;
        margin: 10px 0;
        color: ${theme.color.light};
        padding: 16px;

        ::placeholder {
          color: ${theme.color.gray};
          opacity: 1;
        }

        :invalid {
          border: 2px ${theme.color.primary} solid;
        }

        :focus {
          border-color: white;
        }
        
      }
    }
  }

  .react-datepicker {
    font-family: ${theme.font.family}, Helvetica, Arial, sans-serif !important;
    background-color: ${theme.color.dark} !important;
    color: ${theme.color.light} !important;
    border-color: ${theme.color.darkGray} !important;
  }

  .react-datepicker__day-name {
    color: ${theme.color.darkGray} !important;
  }

  .react-datepicker__day, .react-datepicker__time-name{
    color: ${theme.color.light} !important;
  }

  .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled{
    color: ${theme.color.gray} !important;
  } */

  .react-datepicker__input-container {
    display: block !important;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: ${theme.color.primary} !important;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: ${theme.color.primary} !important;
  }
  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
    background-color: ${theme.color.primary} !important;
  }
  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
    background-color: ${darken(0.2, theme.color.primary)} !important;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    background-color: ${theme.color.primary} !important;
  }
  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover {
    background-color: ${darken(0.2, theme.color.primary)} !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: ${theme.color.primary} !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover {
    background-color: ${darken(0.2, theme.color.primary)} !important;
  }
  .react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
    background-color: ${theme.color.primary} !important;
  }
  .react-datepicker__close-icon::after {
    background-color: ${theme.color.primary} !important;
  }
  .react-datepicker__day:hover {
    background-color: ${theme.color.darkGray} !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__header {
    background-color: ${lighten(0.05, theme.color.dark)} !important;
    border-color: ${theme.color.darkGray} !important;
  }
  .react-datepicker__current-month {
    color: ${theme.color.darkGray} !important;
  }

  .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
    border-bottom-color: ${lighten(0.05, theme.color.dark)} !important;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{
    border-bottom-color: ${theme.color.darkGray} !important;
  }

  .react-datepicker__navigation--previous, .react-datepicker__navigation--next{
    width: 10px !important;
    height: 10px !important;
    margin: 0 !important;
  }
`
