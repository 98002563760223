import card1 from '../images/card-1.jpg'
import card2 from '../images/card-2.jpg'
import card3 from '../images/card-3.jpg'
import card4 from '../images/card-4.jpg'

export const cards = [
  {
    id: 1,
    bg: card1,
    color: '#035698',
    caption: 'Bands',
    title: 'All bands in one place',
    text:
      'Are you a session musician? Are you playing with many artists? Add your band members to each band, and keep track of all of them in one place.',
  },
  {
    id: 2,
    bg: card2,
    color: '#352D54',
    caption: 'Musicians',
    title: 'Meet other musicians',
    text:
      "Did you recently move into a new city? Are you eager to play again but don't know where to find people to play with? Simply search with Oktave, by selecting genre, instrument type or location anytime, anywhere.",
  },
  {
    id: 3,
    bg: card3,
    color: '#793260',
    caption: 'Tasks',
    title: 'Organize your flow',
    text:
      "Add transparency by structuring your tasks and let everyone involved be informed of every process of your band's success.",
  },
  {
    id: 4,
    bg: card4,
    color: '#033043',
    caption: 'Moments',
    title: 'Never miss a practice',
    text:
      'Organize your calendar and let Oktave notify you and your band members of when to go to practice, perform live, have an interview, photo or a video session.',
  },
]

export const about = {
  title: 'About ♩Oktave',
  text:
    'Oktave is a platform for people within the Music Industry to find and work with each other. Band Members, Session musicians, Booking Agents, Managers, Venues, Producers, Studios, etc. Our mission is to bridge the gap between musicians and their goals by creating and expanding the network of people and venues in the industry. ',
}

export const prices = [
  {
    id: 1,
    title: 'Free',
    price: 0,
    tag: 'free forever',
    features: [
      {
        title: '♩1 band',
      },
      {
        title: '♩1 event per month',
      },
      {
        title: '♩Up to 100MB of space',
      },
      {
        // title: '♩Access to industry professionals',
      },
    ],
  },
  {
    id: 2,
    title: 'Pro',
    price: 9.99,
    tag: 'Billed Annualy',
    features: [
      {
        title: '♩Unlimited bands',
      },
      {
        title: '♩Unlimited events',
      },
      {
        title: '♩Up to 2GB of space',
      },
      {
        // title: '♩Unlimited Access to industry professionals',
      },
    ],
  },
  {
    id: 3,
    title: 'Basic',
    price: 12.99,
    tag: 'Billed Monthly',
    features: [
      {
        title: '♩Unlimited bands',
      },
      {
        title: '♩Unlimited events',
      },
      {
        title: '♩Up to 2GB of space',
      },
      {
        // title: '● Unlimited Access to industry professionals',
      },
    ],
  },
]
