import styled from 'styled-components'
import { lighten } from 'polished'

import { theme } from '~/constants'

export const Options = styled.div`
  position: absolute;
  width: 200px;
  z-index: 1;
  left: calc(50% - 190px);
  top: calc(100% + 10px);
  background: ${theme.color.dark};
  border: 1px solid #262626;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25), 0 10px 30px 0 rgba(0, 0, 0, 0.25);
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;

  button {
    margin: 5px 0;
    /* width: 100%;
    color: ${theme.color.light};
    text-align: center;
    background: none;
    border-top: 1px #262626 solid;
    padding-top: 16px;
    font-size: 14px;

    &:hover {
      background: ${lighten(0.05, theme.color.dark)};
      cursor: pointer;
    } */
  }
`
