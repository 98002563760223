import produce from 'immer'

const INITIAL_STATE = {
  data: [],
  // loading: false,
}

export default function bands(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      // case '@user/GET_BANDS_REQUEST': {
      //   draft.bands = action.payload.band
      //   break
      // }
      // case '@user/CREATE_BAND_REQUEST': {
      //   draft.band = action.payload.band
      //   break
      // }
      // case '@bands/UPDATE_BAND_REQUEST': {
      //   draft.band = action.payload.band
      //   break
      // }

      case '@auth/SIGN_IN_SUCCESS': {
        draft.data = action.payload.bands
        break
      }
      case '@bands/GET_BANDS_SUCCESS': {
        draft.data = action.payload.bands
        break
      }
      case '@bands/CREATE_BAND_SUCCESS': {
        draft.band = action.payload.band
        break
      }
      case '@bands/UPDATE_BAND_SUCCESS': {
        draft.band = action.payload.band
        break
      }

      case '@bands/GET_BANDS_FAILURE':
      case '@bands/CREATE_BAND_FAILURE':
      case '@bands/UPDATE_BAND_FAILURE': {
        break
      }

      case '@auth/SIGN_OUT': {
        draft.bands = null
        draft.band = null
        break
      }
      default:
    }
  })
}
