import { css } from 'styled-components'
import { lighten, darken } from 'polished'

import regular from '~/assets/fonts/BwModelica-Regular.woff'
import medium from '~/assets/fonts/BwModelica-Medium.woff'
import bold from '~/assets/fonts/BwModelica-Bold.woff'
import extraBold from '~/assets/fonts/BwModelica-ExtraBold.woff'

export const theme = {
  color: {
    primary: '#D13B3B',
    secondary: '#358BD4',
    dark: '#171717',
    light: '#FFFFFF',
    gray: '#949BAF',
    darkGray: '#414353',
    border: '#2A2A2A',
    transparent: 'transparent',
    session: '#3BD144',
    practice: '#299EFF',
    concert: '#A218FF',
  },
  font: {
    family: 'BwModelica',
    regular,
    medium,
    bold,
    extraBold,
    h1: '42px',
    h2: '36px',
    h3: '24px',
    h4: '18px',
    h5: '16px',
    body: '16px',
  },
}

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
}

export const presets = {
  default: css`
    text-transform: none;
    background: ${lighten(0.02, theme.color.dark)};
    padding: 16px;
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      margin-left: -4px;
      margin-right: 4px;
    }

    &:hover {
      background: ${lighten(0.08, theme.color.dark)};
    }
  `,
  transparent: css`
    text-transform: none;
    background: none;
    padding: 16px;
    border-radius: 8px;

    &:hover {
      background: ${lighten(0.05, theme.color.dark)};
    }
  `,
  primary: css`
    color: ${theme.color.light};
    background: ${theme.color.primary};

    &:hover {
      color: ${darken(0.2, theme.color.light)} !important;
      background: ${darken(0.1, theme.color.primary)};
    }
  `,
  secondary: css`
    background: ${theme.color.secondary};

    &:hover {
      color: ${darken(0.2, theme.color.light)} !important;
      background: ${darken(0.1, theme.color.secondary)};
    }
  `,
  disabled: css`
    background: ${theme.color.gray};
    &:hover {
      color: ${darken(0.2, theme.color.light)} !important;
      background: ${darken(0.1, theme.color.gray)};
    }
  `,
  selected: css`
    background: ${lighten(0.04, theme.color.dark)};
  `,
  active: css`
    font-weight: bold;
    pointer-events: none;
  `,
  link: css`
    background: none;
    text-transform: none;
    font-weight: bold;
    padding: 0 0 3px;
    border-radius: 0;
    border-bottom: 2px ${theme.color.transparent} solid;

    &:hover {
      border-bottom: 2px ${theme.color.light} solid;
    }
  `,
  icon: css`
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${lighten(0.05, theme.color.dark)};
    }
  `,
  sidebar: css`
    text-transform: none;
    background: none;
    padding: 16px;
    border-radius: 8px;

    margin-top: 8px;
    display: flex;
    flex: 1;
    align-items: center;
    font-size: calc(16px + 0.2vw);

    span {
      margin-left: 8px;
    }

    &:hover {
      background: ${lighten(0.05, theme.color.dark)};
    }
    /* color: ${theme.color.light};
    transition: all 0.2s;
    align-items: center;


      */
  
  `,
  avatar: css`
    font-size: 0;
    padding: 0;
    width: max-content;
    height: max-content;
    border-radius: 50%;
    border: 4px transparent solid;

    &:hover {
      border-color: ${theme.color.secondary};
      /* box-shadow:inset 0px 0px 0px 2px ${theme.color.secondary}; */
    }
  `,
}

export const events = {
  concert: css`
    border-color: ${theme.color.concert};
    color: ${theme.color.concert};
  `,
  practice: css`
    border-color: ${theme.color.practice};
    color: ${theme.color.practice};
  `,
  session: css`
    border-color: ${theme.color.session};
    color: ${theme.color.session};
  `,
}

export const block = {
  backgroundColor: lighten(0.02, theme.color.dark),
  borderWidth: 1,
  borderColor: theme.color.border,
  borderStyle: 'solid',
  borderRadius: 8,
}
