import styled from 'styled-components'
import { lighten } from 'polished'

import { theme } from '~/constants'

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  margin-top: -90px;
  min-width: 330px;
  flex: 0.4;

  padding-top: 16px;
  position: relative;

  @media only screen and (max-width: 1144px) {
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
  }
`

export const Promotion = styled.div`
  background: ${lighten(0.02, theme.color.dark)};
  border: 1px solid ${theme.color.border};
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: flex-start; */
  transition: background 0.2s;
  position: sticky;
  top: 0;
  strong {
    flex-grow: 1;
    text-align: center;
  }
`

export const DateItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 16px;
`

export const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  /* background: ${lighten(0.2, theme.color.dark)}; */
  background: ${theme.color.light};
  border-radius: 10px;

  h4 {
    /* font-size: 24px; */
    font-weight:500;
    color: ${theme.color.primary};
  }

  small {
    font-size: 10px;
    font-weight:500;
    color: ${theme.color.darkGray};
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }
`

export const DateContent = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  margin-left: 10px;

  p {
    font-size: calc(16px + 0.2vw);
    font-weight: bold;
  }
  small {
    font-size: calc(12px + 0.2vw);
    color: ${theme.color.gray};
  }
`

export const Description = styled.span`
  font-size: calc(12px + 0.2vw);
  color: ${theme.color.gray}
  margin: 8px 0;
`

export const Participants = styled.div`
  display: flex;
  margin-bottom: 16px;

  > button {
    margin-right: 4px;
  }
`

export const EventType = styled.div`
  display: flex;
  margin-bottom: 24px;
`

export const Event = styled.div`
  border-radius: 30px;
  text-align: center;
  color: ${lighten(0.4, theme.color.dark)};
  padding: 8px;
  display: flex;
  align-items: center;

  svg {
    path {
      fill: ${lighten(0.4, theme.color.dark)};
    }
  }

  small {
    margin-left: 8px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
  }
`
