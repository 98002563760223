import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container, Block, Avatar, Separator, Button } from '~/components'
import { AddIcon } from '~/assets/icons'
import Musician from './Musician'

import { Grid } from './styles'
import { musicians } from '~/database'

function Musicians() {
  return (
    <Container>
      <Block>
        <h1>Musicians</h1>
        {/* <Block marginTop={40} direction="row">
          <Button preset="avatar">
            <AddIcon size={120} />
          </Button>
          {musicians > 0 &&
            musicians.map(band => (
              <Button
                to={`/musicians/${band.id}`}
                preset="avatar"
                key={band.id}
              >
                <Avatar data={band.avatar} size={120} />
              </Button>
            ))}
        </Block>
        <Separator line top={20} label="Musicians" /> */}
      </Block>
      <Grid>
        {musicians.map(musician => (
          <Musician key={musician.id} musician={musician} />
        ))}
      </Grid>
    </Container>
  )
}

export default withAnalytics(Musicians, {})
