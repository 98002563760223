import React from 'react'
import PropTypes from 'prop-types'
import { AvatarIcon } from '~/assets/icons'

import { AvatarImage } from './styles'

const Avatar = ({ size, data }) => {
  return (
    <>
      {data ? (
        <AvatarImage src={data} size={size} />
      ) : (
        <AvatarIcon size={size} />
      )}
    </>
  )
}

export default Avatar

Avatar.propTypes = {
  data: PropTypes.string,
  size: PropTypes.number,
}

Avatar.defaultProps = {
  data: null,
  size: 40,
}
