import styled from 'styled-components'
import { darken } from 'polished'
import { Form as Unform } from '@rocketseat/unform'
import { theme } from '~/constants'

export const Section = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 10px;
  }

  p {
    line-height: 28px;
  }

  @media only screen and (max-width: 991px) {
  }
`

export const Form = styled(Unform)`
  margin-top: 5%;
  background: #222222;
  border-radius: 4px;
  width: 520px;
  display: flex;
  flex-direction: column;
  padding: 36px 48px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);

  strong {
    margin-bottom: 24px;
  }

  small {
    margin: 24px 0;
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  a {
    color: ${theme.color.primary};
    font-weight: 700;
    transition: all 0.2s;

    &:hover {
      color: ${darken(0.2, theme.color.primary)};
    }
  }

  @media only screen and (max-width: 520px) {
    width: 100%;
  }
`

export const NameContainer = styled.div`
  display: flex;

  div {
    flex: 0.5;
  }

  div:first-child {
    margin-right: 10px;
  }

  div:last-child {
    margin-left: 10px;
  }
`

export const FormContainer = styled.div`
  background: #222222;
  border-radius: 4px;
  width: 520px;
  display: flex;
  flex-direction: column;
  padding: 36px 48px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);

  strong {
    margin-bottom: 24px;
  }

  small {
    margin-top: 24px;
  }

  a {
    color: ${theme.color.primary};
    font-weight: 700;
    transition: all 0.2s;

    &:hover {
      color: ${darken(0.2, theme.color.primary)};
    }
  }
  label {
    color: ${theme.color.gray};
    text-transform: uppercase;
    font-family: ${theme.font.family};
    font-size: 12px;
    letter-spacing: 1.5px;
  }

  input[type='text'],
  input[type='email'] {
    border: none !important;
    border-radius: 0 !important;
    border: solid ${theme.color.gray} 1px !important;
    padding: 8px 0;
    color: ${theme.color.light};
    margin: 0 0 10px;
    background: ${theme.color.transparent};
    text-indent: 0 !important;
    ::placeholder {
      color: ${theme.color.gray};
      opacity: 1;
    }
  }

  input[type='submit'] {
  }

  @media only screen and (max-width: 520px) {
    width: 100%;
  }
`
