import React from 'react'

import { Container } from './styles'

const Footer = ({ auth }) => {
  const year = new Date().getFullYear()
  const copy = `All Rights Reserved ©${year} - Oktave, Inc.`
  const authCopy = `©${year} ♩Oktave, Inc`

  return (
    <Container>
      <small>{auth ? authCopy : copy}</small>
    </Container>
  )
}

export default Footer
