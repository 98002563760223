import React from 'react'
import PropTypes from 'prop-types'

const TicketIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M18.3 1.111c1.634 0 2.227.17 2.824.49.598.32 1.067.788 1.386 1.386l.113.226c.247.536.377 1.168.377 2.599v11.376c0 1.635-.17 2.228-.49 2.825a3.332 3.332 0 01-1.386 1.386l-.226.113c-.497.229-1.078.358-2.304.375L5.7 21.889c-1.634 0-2.227-.17-2.824-.49a3.332 3.332 0 01-1.386-1.386l-.113-.226c-.23-.498-.358-1.079-.375-2.304L1 5.812c0-1.635.17-2.228.49-2.825A3.332 3.332 0 012.876 1.6l.226-.113c.536-.247 1.168-.377 2.598-.377h12.6zm.129 2.032H5.57a2.572 2.572 0 00-2.564 2.38L3 5.713v11.572a2.572 2.572 0 002.38 2.564l.191.007H18.43a2.572 2.572 0 002.564-2.38l.007-.191V5.714a2.572 2.572 0 00-2.38-2.564l-.191-.007zM14.5 17a.5.5 0 110 1h-5a.5.5 0 110-1h5zM12.281 4.965l1.123 3.323 3.507.04a.315.315 0 01.185.569l-2.814 2.094 1.045 3.349a.315.315 0 01-.483.35l-2.861-2.029-2.862 2.03a.315.315 0 01-.483-.352l1.045-3.348L6.87 8.896a.315.315 0 01.185-.568l3.507-.04 1.123-3.324a.315.315 0 01.597 0z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default TicketIcon

TicketIcon.propTypes = {
  props: PropTypes.string,
}

TicketIcon.defaultProps = {
  props: '',
}
