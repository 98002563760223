import React from 'react'
import PropTypes from 'prop-types'
// import { useDispatch, useSelector } from 'react-redux'

import { withAnalytics } from '~/hocs/withAnalytics'

import api from '~/services/api'
import history from '~/services/history'

import {
  Container,
  Avatar,
  Button,
  Separator,
  Image,
  Block,
  Text,
} from '~/components'
import { AddIcon, ChevronIcon, MoreIcon } from '~/assets/icons'
import BandSidebar from './BandSidebar'

import { Top, Content, TabBar, CoverImage } from './styles'
import { bands } from '~/database'

function BandProfile({ match }) {
  const { id } = match.params
  // const [loading, setLoading] = React.useState(true)
  const [band, setBand] = React.useState(bands[0])
  const {
    name,
    image,
    bio,
    avatar,
    audio,
    influences,
    genres,
    location,
    longitude,
    latitude,
    users,
  } = band

  React.useEffect(() => {
    async function loadBand() {
      try {
        const response = await api.get(`/bands/${id}`)

        setBand(response.data)
      } catch (err) {
        // force user back if no band was found
        // history.push('/bands')
      }
    }

    loadBand()
  }, [id])

  console.tron.log(band)

  function goBack() {
    history.push('/bands')
  }

  return (
    <Container>
      <Block flex={0.1} marginBottom={20}>
        <Button
          preset="default"
          style={{ width: '-moz-max-content' }}
          onClick={goBack}
        >
          <ChevronIcon />
          Back to Bands
        </Button>
        <Block block marginTop={20}>
          <CoverImage>
            <Image src={image} faded w="100%" h={300} />
            <div>
              <h5>{name}</h5>
              <small>{location}</small>
            </div>
          </CoverImage>

          <TabBar>
            <Avatar data={avatar} size={120} />
            <Button preset="link" to="/bands/1">
              About
            </Button>
            <Button preset="link" to="/bands/1">
              Members
            </Button>
            <Button preset="link" to="/bands/1">
              Photos
            </Button>
            <Button preset="link" to="/bands/1">
              Media
            </Button>
          </TabBar>
        </Block>
      </Block>
      <Content>
        <Block block padding={20}>
          <Block
            direction="row"
            flex={0.1}
            justify="space-between"
            align="center"
          >
            <h4>About</h4>
            <Button preset="icon">
              <MoreIcon />
            </Button>
          </Block>
          <Separator line top={10} />

          {bio && (
            <>
              <Separator label="Bio" />
              <Text size={16}>{bio}</Text>
            </>
          )}

          {genres && (
            <>
              <Separator top={20} label="Genres" />
              <Text size={16}>{genres}</Text>
            </>
          )}

          {influences && (
            <>
              <Separator top={20} label="Influences" />
              <Text size={16}>{influences}</Text>
            </>
          )}

          <Separator top={20} label="Members" />
          <Block>
            <Button preset="avatar">
              <AddIcon />
            </Button>
            {users &&
              users.length > 0 &&
              users.map(user => <Avatar key={users.id} data={user.avatar} />)}
          </Block>
        </Block>
        <BandSidebar />
      </Content>
    </Container>
  )
}

export default withAnalytics(BandProfile, {})

BandProfile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.number,
  }).isRequired,
}

BandProfile.defaultProps = {}
