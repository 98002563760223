import React from 'react'
import PropTypes from 'prop-types'

import { useWindowSize } from '~/hooks'

function LogoIcon({ props, size }) {
  const [width] = useWindowSize()
  return (
    <>
      {width < 1144 ? (
        <svg width={size} height={size} viewBox="0 0 17 26" {...props}>
          <path
            scale={1}
            d="M9.082 1.14h.002c.047.713.463 1.404 1.41 1.925.09.05 1.744.849 2.322 1.184C14.848 5.428 16 6.863 16 8.906c0 2.238-1.068 3.97-3.151 5.153l-1.043-.583c2.153-1.02 3.196-2.524 3.196-4.57 0-1.616-.93-2.775-2.687-3.794-.537-.312-2.178-1.105-2.302-1.173a5.11 5.11 0 01-.93-.645v16.507c0 1.293-.081 1.724-.405 2.398C7.897 23.788 5.903 25 4.044 25 2.266 25 1 23.923 1 22.36c0-1.966 2.182-3.663 4.715-3.663.862 0 1.428.108 2.128.458V1h1.24v.14z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
      ) : (
        <svg width={126} height={36} viewBox="0 0 126 36" {...props}>
          <g fill="#FFF" fillRule="evenodd">
            <path d="M27.798 21.034c3.619 0 6.53 3.142 6.53 6.995 0 3.852-2.911 6.994-6.53 6.994-3.618 0-6.53-3.142-6.53-6.994 0-3.853 2.912-6.995 6.53-6.995zm17.53.307v6.906l7.02-6.906h1.366l-4.96 4.943 5.465 8.276h-1.385l-4.942-7.535-2.565 2.5v5.035h-1.142V21.34h1.142zm27.235 0v1.037h-4.624V34.56h-1.142V22.378h-4.642V21.34h10.408zm12.596 0l5.28 13.219h-1.236l-1.61-4.036h-6.29l-1.591 4.036h-1.198l5.298-13.22h1.347zm12.578 0l4.53 11.942 4.587-11.942h1.198l-5.186 13.219h-1.235l-5.204-13.22h1.31zm27.797 0v1.018h-7.263v4.85h6.889v1.056h-6.89v5.258h7.582l-.262 1.037h-8.461V21.34h8.405zm-97.736.825c-2.963 0-5.386 2.614-5.386 5.863 0 3.248 2.423 5.862 5.386 5.862s5.387-2.614 5.387-5.862c0-3.249-2.424-5.863-5.387-5.863zm56.631.415l-2.714 6.906h5.466l-2.752-6.906zM9.091.92h.001c.05.734.483 1.446 1.47 1.983.093.051 1.817.876 2.42 1.222 2.118 1.215 3.319 2.694 3.319 4.8 0 2.308-1.113 4.095-3.285 5.313l-1.087-.6c2.245-1.052 3.332-2.603 3.332-4.713 0-1.665-.97-2.86-2.8-3.91-.561-.322-2.271-1.14-2.4-1.21a5.327 5.327 0 01-.97-.665v17.019c0 1.333-.085 1.777-.422 2.472-.814 1.638-2.892 2.888-4.83 2.888-1.853 0-3.172-1.111-3.172-2.722 0-2.027 2.274-3.777 4.914-3.777.898 0 1.488.111 2.218.472V.773h1.292V.92z" />
            <path d="M9.09.919h.002c.05.734.483 1.446 1.47 1.983.093.051 1.817.876 2.42 1.222 2.118 1.215 3.319 2.694 3.319 4.8 0 2.308-1.113 4.095-3.285 5.313l-1.087-.6c2.245-1.052 3.332-2.603 3.332-4.713 0-1.665-.97-2.86-2.8-3.91-.561-.322-2.271-1.14-2.4-1.21a5.327 5.327 0 01-.97-.665v17.019c0 1.333-.085 1.777-.422 2.472-.814 1.638-2.892 2.888-4.83 2.888-1.853 0-3.172-1.111-3.172-2.722 0-2.027 2.274-3.777 4.914-3.777.898 0 1.488.111 2.218.472V.773h1.292V.92z" />
          </g>
        </svg>
      )}
    </>
  )
}

export default LogoIcon

LogoIcon.propTypes = {
  props: PropTypes.string,
  size: PropTypes.number,
}

LogoIcon.defaultProps = {
  props: null,
  size: 40,
}
