import React from 'react'
import PropTypes from 'prop-types'

const AvatarIcon = ({ props, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" {...props}>
      <defs>
        <path
          d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0z"
          id="prefix__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <use fill="#414353" xlinkHref="#prefix__a" />
        <path
          scale={1}
          d="M20.193 28.534c6.243 0 12.192 2.48 12.192 5.537 0 3.058-5.95 5.929-12.192 5.929C13.949 40 8 37.129 8 34.071c0-3.058 5.95-5.537 12.193-5.537zm0-14.534c3.27 0 5.248 2.57 5.248 6.056 0 3.49-2.566 6.056-5.248 6.056-2.687 0-5.249-2.557-5.249-6.056 0-3.486 1.979-6.056 5.249-6.056z"
          fill="#949BAF"
          mask="url(#prefix__b)"
        />
      </g>
    </svg>
  )
}

export default AvatarIcon

AvatarIcon.propTypes = {
  props: PropTypes.string,
  size: PropTypes.number,
}

AvatarIcon.defaultProps = {
  props: null,
  size: 40,
}
