import styled from 'styled-components'
import { block } from '~/constants'

export const Container = styled.div`
  display: flex;
  flex: ${props => props.flex};
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  padding: ${props => props.padding || 0}px;
  margin: ${props => props.margin || 0}px;
  margin-top: ${props => props.marginTop}px;
  margin-right: ${props => props.marginRight}px;
  margin-bottom: ${props => props.marginBottom}px;
  margin-left: ${props => props.marginLeft}px;
  position: relative;

  ${props => props.block && block};
`
