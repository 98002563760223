import styled from 'styled-components'
// import { Link } from 'react-router-dom'
// import { darken } from 'polished'

// import { theme } from '~/constants'

export const Container = styled.main`
  width: 100%;
  min-height: 100vh;
  max-width: 1240px;
  display: flex;
  flex: 1;
  margin: 0 auto;
  align-self: center;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 120px;

  @media only screen and (max-width: 1144px) {
    padding: 0 50px;
  }

  @media only screen and (max-width: 991px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 420px) {
    padding: 0 20px;
    max-width: 100%;
  }
`

export const AuthContainer = styled(Container)`
  height: 100%;
  flex: 0.8;
  flex-grow: 1;
  padding: 0 60px;
`

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  max-width: 100vw;
  flex: 1;
  align-self: center;
  align-items: flex-start;
  /* justify-content: space-between; */
  flex-direction: row;
`
