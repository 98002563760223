import React from 'react'

import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { withAnalytics } from '~/hocs/withAnalytics'

import { signInRequest } from '~/store/modules/auth/actions'
import { schemaLogin } from '~/utils'

import { Container, Button, Input } from '~/components'
import { Section, Form } from '../styles'

function SignIn() {
  const dispatch = useDispatch()

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password))
  }

  const demo = {
    email: 'yara@ydra.is',
    password: '123456',
  }

  return (
    <Container>
      <Section>
        <Form schema={schemaLogin} initialData={demo} onSubmit={handleSubmit}>
          <strong>Sign in</strong>
          <Input
            name="email"
            label="Email"
            type="email"
            placeholder="john@johnsson.is"
          />
          <Input
            name="password"
            label="Password"
            type="password"
            placeholder="**********"
          />
          <Button preset="primary" type="submit" onSubmit={handleSubmit}>
            Sign In
          </Button>
          <small>
            Not a member? <Link to="/register">Create Account</Link>
          </small>
        </Form>
      </Section>
    </Container>
  )
}

export default withAnalytics(SignIn, {})
