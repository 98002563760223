import React from 'react'
import PropTypes from 'prop-types'

const CalendarIcon = ({ props, active }) => {
  return (
    <>
      {active ? (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M15 2a1 1 0 011 1v2h2.77c1.47 0 2.004.157 2.542.45.538.295.96.727 1.247 1.277l.096.195C22.881 7.418 23 8 23 9.327v9.346c0 1.505-.153 2.05-.44 2.6-.288.55-.71.982-1.248 1.276l-.191.098c-.485.231-1.053.353-2.351.353H5.23c-1.47 0-2.004-.157-2.542-.45a3.033 3.033 0 01-1.247-1.277l-.096-.195c-.21-.463-.329-1-.343-2.149L1 9.327c0-1.505.153-2.05.44-2.6.288-.55.71-.982 1.248-1.276l.191-.098c.453-.216.978-.336 2.101-.351L8 5V3a1 1 0 112 0v2h4V3a1 1 0 011-1zm-2.6 13h-.8a1 1 0 00-.117 1.993L11.6 17h.8a1 1 0 00.117-1.993L12.4 15zm-4.6 0H7a1 1 0 00-.117 1.993L7 17h.8a1 1 0 00.117-1.993L7.8 15zm9.2-4h-.8a1 1 0 00-.117 1.993L16.2 13h.8a1 1 0 00.117-1.993L17 11zm-4.6 0h-.8a1 1 0 00-.117 1.993L11.6 13h.8a1 1 0 00.117-1.993L12.4 11zm-4.6 0H7a1 1 0 00-.117 1.993L7 13h.8a1 1 0 00.117-1.993L7.8 11z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M15 2a1 1 0 011 1v2h2.77c1.47 0 2.004.157 2.542.45.538.295.96.727 1.247 1.277l.096.195C22.881 7.418 23 8 23 9.327v9.346c0 1.505-.153 2.05-.44 2.6-.288.55-.71.982-1.248 1.276l-.191.098c-.485.231-1.053.353-2.351.353H5.23c-1.47 0-2.004-.157-2.542-.45a3.033 3.033 0 01-1.247-1.277l-.096-.195c-.21-.463-.329-1-.343-2.149L1 9.327c0-1.505.153-2.05.44-2.6.288-.55.71-.982 1.248-1.276l.191-.098c.453-.216.978-.336 2.101-.351L8 5V3a1 1 0 112 0v2h4V3a1 1 0 011-1zM8 7H5.077c-1.032 0-1.285.05-1.54.192a.834.834 0 00-.352.366l-.05.107c-.09.222-.129.512-.134 1.285L3 18.847c0 1.07.049 1.331.185 1.595a.834.834 0 00.353.366l.103.052c.232.102.541.14 1.436.14h13.846c1.032 0 1.285-.05 1.54-.192a.834.834 0 00.352-.366l.05-.107c.09-.222.129-.512.134-1.285L21 9.153c0-1.07-.049-1.331-.185-1.595a.834.834 0 00-.353-.366l-.103-.052c-.215-.094-.494-.134-1.24-.14H16a1 1 0 01-2 0h-4a1 1 0 11-2 0zm4.4 8a1 1 0 110 2h-.8a1 1 0 110-2h.8zm-4.6 0a1 1 0 010 2H7a1 1 0 010-2h.8zm9.2-4a1 1 0 110 2h-.8a1 1 0 110-2h.8zm-4.6 0a1 1 0 110 2h-.8a1 1 0 110-2h.8zm-4.6 0a1 1 0 010 2H7a1 1 0 010-2h.8z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </>
  )
}

export default CalendarIcon

CalendarIcon.propTypes = {
  props: PropTypes.string,
  active: PropTypes.bool,
}

CalendarIcon.defaultProps = {
  props: null,
  active: false,
}
