import styled, { css } from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { lighten } from 'polished'

import { theme } from '~/constants'

export const Container = styled.div`
  position: relative;
`

export const Badge = styled.div`
  position: relative;
  ${props =>
    props.hasUnread &&
    css`
      &::after {
        position: absolute;
        right: 14px;
        top: 14px;
        width: 8px;
        height: 8px;
        background: ${theme.color.primary};
        content: '';
        border: 2px solid ${theme.color.dark};
        border-radius: 50%;
      }
    `}
`

export const List = styled.div`
  position: absolute;
  width: 300px;
  z-index: 1;
  left: calc(50% - 250px);
  top: calc(100% + 20px);
  background: ${theme.color.dark};
  border: 1px solid #262626;
  border-radius: 8px;
  padding: 15px 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25), 0 10px 30px 0 rgba(0, 0, 0, 0.25);
  display: ${props => (props.open ? 'block' : 'none')};

  button{
    width: 100%;
    color: ${theme.color.light};
    text-align: center;
    background: none;
    border-top: 1px #262626 solid;
    padding-top: 16px;
    font-size: 14px;

    &:hover {
      background: ${lighten(0.05, theme.color.dark)};
      cursor: pointer;
    }
  }
  /* &::before {
    content: '';
    position: absolute;
    left: calc(80% - 10px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${theme.color.dark};
  } */


`

export const NotificationItem = styled.div`
  display: flex;

  & + div {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid ${theme.color.darkGray};
  }

  div {
    margin-left: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;

    p {
      display: flex;
      font-size: 14px;
      margin: 5px 0 1px;
      strong {
        margin-right: 5px;
      }
    }

    time {
      display: flex;
      font-size: 12px;
      opacity: 0.6;
    }
  }

  button {
    display: flex;
    font-size: 12px;
    background: none;
    color: white;
    text-transform: none;
  }

  ${props =>
    props.unread &&
    css`
      button::after {
        content: '';
        opacity: 1;
        display: inline-block;
        margin-left: 6px;
        width: 8px;
        height: 8px;
        background: ${theme.color.primary};
        border-radius: 50%;
      }
    `}
`

export const Scroll = styled(PerfectScrollbar)`
  max-height: 300px;
  padding: 5px 16px;
`
