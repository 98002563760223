import styled from 'styled-components'

export const AvatarImage = styled.img`
  border-radius: ${props => props.size / 2}px;
  object-fit: cover;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;

  /* border: 4px solid purple; */
`
