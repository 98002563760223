import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import api from '~/services/api'

import { updateProfileSuccess, updateProfileFailure } from './actions'

export function* updateProfile({ payload }) {
  try {
    const { username, email, ...rest } = payload.data

    const profile = {
      username,
      email,
      ...(rest.oldPassword ? rest : {}),
    }

    const response = yield call(api.put, 'users/profile', profile)

    toast.success('Profile was successfully updated!')

    yield put(updateProfileSuccess(response.data))
  } catch (err) {
    toast.error('Unable to update Profile, please verify your information')

    yield put(updateProfileFailure())
  }
}

export default all([takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile)])
