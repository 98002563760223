import React from 'react'
import PropTypes from 'prop-types'

const ImageIcon = ({ props, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 120 120" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M60 0c33.137 0 60 26.863 60 60s-26.863 60-60 60S0 93.137 0 60 26.863 0 60 0z"
          fill="#949BAF"
        />
        <path
          d="M60 0c33.137 0 60 26.863 60 60 0 6.99-1.195 13.7-3.393 19.939a8.408 8.408 0 00-.811-1.172l-.232-.269L91.524 51.8a5.443 5.443 0 00-7.917-.181l-.171.181-25.064 27.833-10.373-11.52a5.443 5.443 0 00-7.917-.181l-.17.181-26.615 29.558A59.75 59.75 0 010 60C0 26.863 26.863 0 60 0zm-5 30c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11z"
          fill="#DCDCDF"
        />
      </g>
    </svg>
  )
}

export default ImageIcon

ImageIcon.propTypes = {
  props: PropTypes.string,
  size: PropTypes.number,
}

ImageIcon.defaultProps = {
  props: null,
  size: 40,
}
