import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { theme } from '~/constants'

export const Picture = styled(LazyLoadImage)`
  object-fit: contain;
  opacity: ${props => (props.faded ? 0.4 : 1)} !important;
`

export const EmptyImage = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: ${props => props.width}px;
  max-width: ${props => props.width}px;
  height: ${props => props.height}px;
  max-height: ${props => props.height}px;
  background: ${theme.color.light};

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
  }
`
