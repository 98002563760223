import styled from 'styled-components'
import { lighten } from 'polished'

import { theme } from '~/constants'

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  min-width: 330px;
  flex: 0.5;
  position: relative;

  /* border-left: 1px ${theme.color.darkGray} solid; */

  @media only screen and (max-width: 1144px) {
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }

  @media only screen and (max-width: 768px) {
  }
`

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;

  div {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    p {
      font-size: calc(16px + 0.2vw);
      font-weight: bold;
    }
    small {
      font-size: calc(14px + 0.2vw);
    }
  }
`
