import React from 'react'
import PropTypes from 'prop-types'

const AddContactIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M15.26 12.142c1.225 1.597 1.369 3.3-.03 4.7l-1.351 1.35c-.399 1.94-1.065 3.4-2.035 4.37-2.215 2.216-5.32 1.773-7.75-.656-2.43-2.43-2.872-5.535-.656-7.75.97-.97 2.43-1.636 4.37-2.035l1.35-1.351c1.4-1.399 3.103-1.255 4.7-.03l4.948-4.947a.99.99 0 010-1.401l2.102-2.102a.99.99 0 011.4 0l1.402 1.401a.99.99 0 010 1.401l-2.102 2.102a.99.99 0 01-1.4 0l-4.949 4.948zm-5.77 4.163a1.486 1.486 0 11-2.101 2.102 1.486 1.486 0 012.102-2.102zM5.5 1c.621 0 1.125.504 1.125 1.125v2.248h2.25a1.125 1.125 0 010 2.25h-2.25v2.252a1.125 1.125 0 01-2.25 0V6.623h-2.25a1.125 1.125 0 110-2.25h2.25V2.125C4.375 1.504 4.879 1 5.5 1z"
        fill="#477BB5"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default AddContactIcon

AddContactIcon.propTypes = {
  props: PropTypes.string,
}

AddContactIcon.defaultProps = {
  props: null,
}
