export function getBandsRequest() {
  return {
    type: '@bands/GET_BANDS_REQUEST',
  }
}

export function getBandsSuccess(bands) {
  return {
    type: '@bands/GET_BANDS_SUCCESS',
    payload: { bands },
  }
}

export function getBandsFailure() {
  return {
    type: '@bands/GET_BANDS_FAILURE',
  }
}

export function createBandRequest(data) {
  return {
    type: '@bands/CREATE_BAND_REQUEST',
    payload: { data },
  }
}

export function createBandSuccess(band) {
  return {
    type: '@bands/CREATE_BAND_SUCCESS',
    payload: { band },
  }
}

export function createBandFailure() {
  return {
    type: '@bands/CREATE_BAND_FAILURE',
  }
}

export function updateBandRequest(data) {
  return {
    type: '@bands/UPDATE_BAND_REQUEST',
    payload: { data },
  }
}

export function updateBandSuccess(band) {
  return {
    type: '@bands/UPDATE_BAND_SUCCESS',
    payload: { band },
  }
}

export function updateBandFailure() {
  return {
    type: '@bands/UPDATE_BAND_FAILURE',
  }
}
