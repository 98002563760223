import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { AuthLayout, DefaultLayout } from '../pages/_layouts'
import { store } from '../store' //eslint-disable-line

export default function RouteWrap({
  component: Component,
  authenticated = false,
  ...rest
}) {
  const { signed } = store.getState().auth
  // const signed = true

  if (!signed && authenticated) {
    return <Redirect to="/login" />
  }

  if (signed && !authenticated) {
    return <Redirect to="/feed" />
  }

  const Layout = signed ? AuthLayout : DefaultLayout

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

RouteWrap.propTypes = {
  authenticated: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
}

RouteWrap.defaultProps = {
  authenticated: false,
}
