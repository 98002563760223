export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  }
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  }
}

export function signUpRequest(name, lastname, birthday, email, password) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { name, lastname, birthday, email, password },
  }
}

export function signUpSuccess(token, user) {
  return {
    type: '@auth/SIGN_UP_SUCCESS',
    payload: { token, user },
  }
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  }
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  }
}
