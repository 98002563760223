import React from 'react'
import PropTypes from 'prop-types'

const CommentIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M5 19.002c-2.224 0-4-1.819-4-4V7c0-2.253 1.747-4 4-4h14c2.252 0 4 1.748 4 4v8.001a4.014 4.014 0 01-3 3.874l-1.466 4.06a1 1 0 01-1.647.367l-3.715-3.715a2 2 0 00-1.414-.585H5zm13.801-2H19c1.108 0 2-.913 2-2V7c0-1.147-.852-2-2-2H5c-1.148 0-2 .852-2 2v8.001c0 1.088.892 2 2 2h7.618a2 2 0 011.375.548l3.207 3.038.625-2.803a1 1 0 01.976-.782z"
        fill="#949BAF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default CommentIcon

CommentIcon.propTypes = {
  props: PropTypes.string,
}

CommentIcon.defaultProps = {
  props: null,
}
