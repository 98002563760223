import React from 'react'
import { useSelector } from 'react-redux'

import api from '~/services/api'
// import history from '~/services/history'

import { schemaPost } from '~/utils'

import { CameraIcon, CloseIcon } from '~/assets/icons'

import { Avatar, Modal, Separator, Button } from '~/components'
import CreatePostImage from './CreatePostImage'

import { PostTop, Form, FeedHeader, PostInput, CreatePostText } from './styles'

const CreatePost = () => {
  const profile = useSelector(state => state.user.profile)
  const [loading, setLoading] = React.useState(false)
  const [isVisible, setVisible] = React.useState(false)

  async function handleCreatePost(data) {
    setLoading(true)
    await api.post('posts', {
      ...data,
    })

    setLoading(false)
    setVisible(false)
  }

  function toggleModal() {
    setVisible(!isVisible)
  }

  return (
    <>
      <FeedHeader onClick={toggleModal}>
        <Avatar data={profile.avatar} size={60} />
        <p>What&apos;s going on?</p>
        <Button preset="icon">
          <CameraIcon />
        </Button>
      </FeedHeader>

      <Modal isVisible={isVisible} toggle={toggleModal} title="Create Post">
        <PostTop>
          <Avatar data={profile.avatar} size={60} />
          <div>
            <p>{profile.name}</p>
            <time>Sharing a post</time>
          </div>
        </PostTop>
        <Separator line margin={20} />

        <Form schema={schemaPost} onSubmit={handleCreatePost}>
          <CreatePostImage name="file_id" />
          <CreatePostText>
            <PostInput
              name="content"
              type="text"
              multiline
              placeholder="Tell us what's new"
            />
          </CreatePostText>
          <Button type="submit" preset="secondary" loading={loading}>
            Create Post
          </Button>
        </Form>
      </Modal>
    </>
  )
}

export default CreatePost
