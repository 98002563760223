import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { Image } from '~/components'

import {
  MusicianItem,
  MusicianContainer,
  Interact,
  Information,
} from './styles'
import { CloseIcon, AddContactIcon, StarIcon } from '~/assets/icons'

const Musician = ({ musician, onClick, onPass, onAdd, onFollow }) => {
  const { name, age, instruments, genre, location, image } = musician
  return (
    <MusicianContainer>
      <MusicianItem>
        <Information>
          <div>
            <p>
              {name}, {age}
            </p>
            <small>{instruments}</small>
          </div>
          <div>
            <p>{location}</p>
            <small>{genre}</small>
          </div>
        </Information>
        <Image src={image} w={335} h={500} alt={`${name}`} />
      </MusicianItem>
      <Interact>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          type="button"
          onClick={onPass}
        >
          <CloseIcon />
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          type="button"
          onClick={onAdd}
        >
          <AddContactIcon />
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          type="button"
          onClick={onFollow}
        >
          <StarIcon />
        </motion.button>
      </Interact>
    </MusicianContainer>
  )
}

export default Musician

Musician.propTypes = {
  musician: PropTypes.shape({
    name: PropTypes.string,
    age: PropTypes.number,
    instruments: PropTypes.string,
    genre: PropTypes.string,
    location: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  onAdd: PropTypes.func,
  onFollow: PropTypes.func,
  onClick: PropTypes.func,
  onPass: PropTypes.func,
}

Musician.defaultProps = {
  onAdd: null,
  onFollow: null,
  onClick: null,
  onPass: null,
}
