import React from 'react'
import PropTypes from 'prop-types'

import 'react-lazy-load-image-component/src/effects/blur.css'

import { ImageIcon } from '~/assets/icons'
import { EmptyImage, Picture } from './styles'

const Image = ({ h, w, src, size, faded }) => {
  return (
    <>
      {src ? (
        <Picture
          faded={faded}
          src={src}
          width={w}
          height={h}
          alt={`${src}`}
          effect="blur"
        />
      ) : (
        <EmptyImage width={w} height={h}>
          <ImageIcon size={size} />
        </EmptyImage>
      )}
    </>
  )
}

export default Image

Image.propTypes = {
  faded: PropTypes.bool,
  h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  w: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number,
  src: PropTypes.string,
}

Image.defaultProps = {
  faded: false,
  h: 100,
  w: 100,
  size: 120,
  src: null,
}
