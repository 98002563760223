import React from 'react'
import PropTypes from 'prop-types'

const NotificationIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M15 20a3 3 0 01-6 0zM12 1a2 2 0 011.976 2.309 6.442 6.442 0 014.458 5.87l.005.26c0 2.072.341 3.853 1.024 5.345l.152.315A2 2 0 0117.83 18H6.234a2 2 0 01-1.779-2.913c.785-1.53 1.155-3.366 1.108-5.508a6.442 6.442 0 014.463-6.27A2 2 0 0112 1z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default NotificationIcon

NotificationIcon.propTypes = {
  props: PropTypes.string,
}

NotificationIcon.defaultProps = {
  props: null,
}
