import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { withAnalytics } from '~/hocs/withAnalytics'

import { signUpRequest } from '~/store/modules/auth/actions'
import { schemaRegister } from '~/utils'

import { Container, Button, Input, InputDate, Spacer } from '~/components'
import { Section, Form, NameContainer } from '../styles'

function SignUp() {
  const loading = useSelector(state => state.auth.loading)
  const dispatch = useDispatch()

  function handleSubmit({ name, lastname, birthday, email, password }) {
    dispatch(signUpRequest(name, lastname, birthday, email, password))
  }

  const demo = {
    name: 'Yara',
    lastname: 'Polana',
    birthday: new Date(1988, 7, 9),
    email: 'yara@ydra.is',
    password: '123456',
  }

  return (
    <Container>
      <Section>
        <Form
          schema={schemaRegister}
          initialData={demo}
          onSubmit={handleSubmit}
        >
          <strong>Sign up with email address</strong>
          <NameContainer>
            <Input
              name="name"
              label="first name"
              type="text"
              placeholder="John"
            />
            <Input
              name="lastname"
              label="last name"
              type="text"
              placeholder="Johnsson"
            />
          </NameContainer>
          <InputDate
            name="birthday"
            label="Birthday"
            type="date"
            placeholder="Select a date"
          />
          <Input
            name="email"
            label="Email"
            type="email"
            placeholder="john@johnsson.is"
          />
          <Input
            name="password"
            label="Password"
            type="password"
            placeholder="**********"
          />
          <small>
            By clicking Sign up, you agree with our{' '}
            <Link to="/terms">Terms and Conditions.</Link>
          </small>
          <Button
            preset="primary"
            type="submit"
            loading={loading}
            onSubmit={handleSubmit}
          >
            Sign Up
          </Button>
          <small>
            Already have an account? <Link to="/login">Login</Link>
          </small>
        </Form>
      </Section>
      <Spacer />
    </Container>
  )
}

export default withAnalytics(SignUp, {})
