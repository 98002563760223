import React from 'react'
import PropTypes from 'prop-types'

const PromotionsIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.605 1.002L17.155 1c1.336 0 1.821.14 2.31.4.49.262.873.646 1.134 1.135l.093.185c.201.438.308.956.308 2.126v14.308c0 1.337-.14 1.822-.4 2.311a2.726 2.726 0 01-1.135 1.134l-.185.093c-.407.187-.882.292-1.885.306L6.845 23c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 013.4 21.464l-.093-.185c-.187-.407-.292-.882-.306-1.885L3 4.845c0-1.336.14-1.821.4-2.31A2.726 2.726 0 014.536 1.4l.185-.093c.407-.187.882-.292 1.885-.306zM16 18H8a1 1 0 00-.117 1.993L8 20h8a1 1 0 00.117-1.993L16 18zm1.5-15h-11a1.5 1.5 0 00-1.493 1.356L5 4.5v10a1.5 1.5 0 001.356 1.493L6.5 16h11a1.5 1.5 0 001.493-1.356L19 14.5v-10a1.5 1.5 0 00-1.356-1.493L17.5 3zm-7 3.934a.5.5 0 01.277.084l3.099 2.066a.5.5 0 010 .832l-3.099 2.066a.5.5 0 01-.777-.416V7.434a.5.5 0 01.5-.5z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default PromotionsIcon

PromotionsIcon.propTypes = {
  props: PropTypes.string,
}

PromotionsIcon.defaultProps = {
  props: null,
}
