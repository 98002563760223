import React from 'react'
import PropTypes from 'prop-types'

const LikeIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M2.925 9.552c.61 0 1.121.12 1.545.347.424.226.756.559.983.983.226.423.347.935.347 1.545v5.25c0 .609-.12 1.12-.347 1.544-.227.424-.56.757-.983.983-.363.195-.791.31-1.29.34l-.305.008c-.61 0-1.121-.121-1.545-.348a2.362 2.362 0 01-.983-.983C.121 18.798 0 18.286 0 17.676v-5.249c0-.61.12-1.122.347-1.545.227-.424.56-.757.983-.983.363-.194.791-.31 1.29-.34l.305-.007zM11.217 2l.124.002c1.938.07 3.646 2.198 2.08 4.693l-.1.155a1.093 1.093 0 00.773 1.698l.13.008h6.77a3 3 0 012.775 4.142l-2.214 5.376a4 4 0 01-3.699 2.478H10.8a4 4 0 01-4-4V9.243a5 5 0 01.206-1.42A27.662 27.662 0 018.43 4.059c.644-1.39 1.666-2 2.663-2.055L11.217 2zm-8.292 9.552l-.188.003c-.213.013-.36.052-.464.107a.365.365 0 00-.162.163 1.125 1.125 0 00-.107.467L2 12.427v5.25c0 .284.043.475.11.601.041.075.088.122.163.163.101.054.244.092.418.106l.136.005.236-.004c.213-.013.36-.052.464-.107a.365.365 0 00.162-.163c.056-.105.096-.255.107-.467l.004-.135v-5.249c0-.285-.043-.476-.11-.602a.365.365 0 00-.163-.163c-.126-.067-.317-.11-.602-.11zm7.32-6.653a25.663 25.663 0 00-1.321 3.492 3 3 0 00-.116.636l-.008.216v7.309a2 2 0 001.85 1.994l.15.006h7.056a2 2 0 001.782-1.093l.068-.146 2.213-5.376a1 1 0 00-.808-1.374l-.116-.007h-6.77a3.093 3.093 0 01-1.736-.533c-1.361-.923-1.754-2.74-.942-4.11l.18-.281c.494-.787.363-1.282-.103-1.532-.47-.254-.97-.085-1.379.8z"
        fill="#949BAF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default LikeIcon

LikeIcon.propTypes = {
  props: PropTypes.string,
}

LikeIcon.defaultProps = {
  props: null,
}
