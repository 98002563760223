import styled from 'styled-components'

export const Top = styled.div`
  h1 {
    margin-bottom: 40px;
  }
`

export const Content = styled.div`
  display: flex;
  flex: 1;
`

export const CoverImage = styled.div`
  display: flex;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  height: 300px;

  background-color: rgba(31, 30, 44);
  position: relative;

  img {
    object-fit: cover;
  }

  div:last-child {
    position: absolute;
    bottom: 16px;
    left: 200px;

    h5 {
      font-size: 18px;
    }

    small {
      font-size: 16px;
    }
  }
`

export const TabBar = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  position: relative;
  padding-left: 200px;

  img {
    position: absolute;
    top: -80px;
    left: 40px;
  }

  a {
    margin-right: 20px;
  }
`
