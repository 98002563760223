import styled from 'styled-components'

export const Section = styled.section`
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
  img {
    margin: 40px 0;
    aspect-ratio: 1;
    width: 100%;
  }

  h1 {
    margin-bottom: 10px;
  }

  p {
    line-height: 28px;
  }
  @media only screen and (max-width: 991px) {
    p {
      text-align: left;
    }
  }
`
