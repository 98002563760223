import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container, Button } from '~/components'
import { Section, CardSection, Image, SignWrap, SignCTA, Card } from './styles'

import bg1 from '~/assets/images/bg-1.jpg'
import { cards } from '~/assets/copy'

function Main() {
  const CardList = () => {
    return cards.map(card => (
      <Card key={card.id} bg={card.bg} color={card.color}>
        <h5>{card.caption}</h5>
        <h2>{card.title}</h2>
        <p>{card.text}</p>
      </Card>
    ))
  }

  return (
    <Container>
      <Section>
        <SignWrap>
          <h1>A Place for the modern musician</h1>
          <h5>Create collaborations and opportunities without limitations</h5>
          <Button preset="primary" to="/beta">
            Sign Up For Beta
          </Button>
        </SignWrap>
        <Image src={bg1} alt="" />
      </Section>
      <CardSection direction="row">
        <CardList />
      </CardSection>
      <Section>
        <SignCTA>
          <h1>Sign up for beta</h1>
          <h5>Join our private beta testing group</h5>
          <Button preset="primary" to="/beta">
            Sign Up For Beta
          </Button>
        </SignCTA>
      </Section>
    </Container>
  )
}

export default withAnalytics(Main, {})
