import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

function Policy() {
  return (
    <div>
      <h1>Policy</h1>
    </div>
  )
}

export default withAnalytics(Policy, {})
