import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container, CalendarItem } from '~/components'

function Calendar() {
  return (
    <Container>
      <h1>Calendar</h1>
      <CalendarItem />
    </Container>
  )
}

export default withAnalytics(Calendar, {})
