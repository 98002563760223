import React from 'react'

import { Container, ContactItem } from './styles'
import { Avatar, Button, Block, Separator } from '~/components'
import { MoreIcon, AddIcon, StarIcon, TicketIcon } from '~/assets/icons'

const InboxSidebar = ({ data }) => (
  <Container>
    <Block block padding={20}>
      <Separator line label="Your Contacts" top={20} />
      <Button key="" preset="transparent" to="">
        <ContactItem>
          <Avatar size={50} />
          <div>
            <p>band.name</p>
            <small>band.name</small>
          </div>
        </ContactItem>
      </Button>
      <Button key="" preset="transparent" to="">
        <ContactItem>
          <Avatar size={50} />
          <div>
            <p>band.name</p>
            <small>band.name</small>
          </div>
        </ContactItem>
      </Button>
      <Button key="" preset="transparent" to="">
        <ContactItem>
          <Avatar size={50} />
          <div>
            <p>band.name</p>
            <small>band.name</small>
          </div>
        </ContactItem>
      </Button>
    </Block>
  </Container>
)

export default InboxSidebar
