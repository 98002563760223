export const feed = [
  {
    id: 1,
    author: 'Kevin',
    avatar: 'https://fullstackmillionaire.com/oktave/avatars/item-avatar-4.png',
    verified: true,
    date: '32 mins ago',
    content:
      "Has anyone figure out the golden ratio system for the transient master? Can't seem to get that nice compression.",
    likes: '7.5 k',
    comments: '4.4 k',
  },
  {
    id: 2,
    author: 'Michael',
    avatar: 'https://fullstackmillionaire.com/oktave/avatars/item-avatar-3.png',
    verified: false,
    date: '32 mins ago',
    content:
      "Has anyone figure out the golden ratio system for the transient master? Can't seem to get that nice compression.",
    image: 'https://fullstackmillionaire.com/oktave/posts/post-1.jpg',
    likes: '7.5 k',
    comments: '4.4 k',
  },
  {
    id: 3,
    author: 'Jimmy',
    avatar: 'https://fullstackmillionaire.com/oktave/avatars/item-avatar-1.png',
    verified: true,
    date: '32 mins ago',
    content:
      "Ask yourself 'what music do I like?' Discover drummers you like. Immerse yourself in music. After all, playing... ",
    likes: '7.5 k',
    comments: '4.4 k',
  },
  {
    id: 4,
    author: 'Michael',
    avatar: 'https://fullstackmillionaire.com/oktave/avatars/item-avatar-3.png',
    verified: false,
    date: '32 mins ago',
    content:
      "Ask yourself 'what music do I like?' Discover drummers you like. Immerse yourself in music. After all, playing... ",
    image: 'https://fullstackmillionaire.com/oktave/posts/post-2.jpg',
    likes: '7.5 k',
    comments: '4.4 k',
  },
]

export const musicians = [
  {
    id: 1,
    image: 'https://fullstackmillionaire.com/oktave/profiles/image1.png',
    name: 'Jimmy',
    age: '32',
    location: 'New York, US',
    genre: 'Rock, Jazz, Ska',
    instruments: 'Drum, Bass',
  },
  {
    id: 2,
    image: 'https://fullstackmillionaire.com/oktave/profiles/image2.png',
    name: 'Vanessa',
    age: '28',
    location: 'New York, US',
    genre: 'Jazz, Ska, R&B',
    instruments: 'Vocals, Back Vocals',
  },
  {
    id: 3,
    image: 'https://fullstackmillionaire.com/oktave/profiles/image3.png',
    name: 'Michael',
    age: '22',
    location: 'Amsterdam, ND',
    genre: 'Rock, Punk',
    instruments: 'Bass',
  },
  {
    id: 4,
    image: 'https://fullstackmillionaire.com/oktave/profiles/image5.png',
    name: 'Andrew',
    age: '25',
    location: 'Berlin, GE',
    genre: 'Acoustic, Soft Rock, Acapella',
    instruments: 'Acoustic Guitar, Bass',
  },
  {
    id: 5,
    image: 'https://fullstackmillionaire.com/oktave/profiles/image9.png',
    name: 'Josh',
    age: '27',
    location: 'Los Angeles, US',
    genre: 'Rock, Metal, Metalcore',
    instruments: 'Vocals, Scream',
  },
  {
    id: 6,
    image: 'https://fullstackmillionaire.com/oktave/profiles/image11.png',
    name: 'Robbert',
    age: '35',
    location: 'New York',
    genre: 'Rap, HipHop, R&B',
    instruments: 'Vocals, Beats',
  },
  {
    id: 7,
    image: 'https://fullstackmillionaire.com/oktave/profiles/image12.png',
    name: 'Carina',
    age: '24',
    location: 'Los Angeles, US',
    genre: 'Electronica, Jazz',
    instruments: 'Vocals',
  },
]

export const user = {
  id: 1,
  image: 'https://fullstackmillionaire.com/oktave/profiles/image4.png',
  name: 'Kevin',
  age: '31',
  verified: true,
  location: 'Reykjavik, IS',
  genres: 'Punk Rock, Ska, Post Rock',
  instruments: 'Guitar, Back Vocals',
  about:
    "I'm kevin johnsson, lead guitarist and member of the bands Riot and Basket. Riot plan on going on tour to release our first debut album.",
  influences: 'Nirvana, Alice in Chains, Bob Marley, Nine Inch Nails.',
}

export const bands = [
  {
    id: 1,
    name: 'Riot',
    avatar: 'https://fullstackmillionaire.com/oktave/bands/band-icon-1.png',
    image: 'https://fullstackmillionaire.com/oktave/bands/band-image-1.jpg',
    bio:
      'Riot is an Icelandic Punk Rock, Ska band, formed by Kevin and Krístín in 2017. Riot became one of the first bands to bring Ska into the Icelandic atmospheric music scene.',
    genres: 'Punk Rock, Ska',
    location: 'Reykjavik, IS',
    influences:
      'Nirvana, Pearl Jam, Lenny Kravitz, Alice in Chains, Bob Marley, The Dave Brubeck Quartet, Miles Davis',
    users: {
      user: 1,
    },
  },
  {
    id: 2,
    name: 'Basket',
    avatar: 'https://fullstackmillionaire.com/oktave/bands/band-icon-2.png',
    // image: 'https://fullstackmillionaire.com/oktave/bands/band-image-2.jpg',
    bio:
      'Basket is an Boom boom flash band, formed in Italy. It is just one of those bands that you want to add to your basket no matter where you are',
  },
]

export const videos = [
  {
    id: 1,
    url: 'https://fullstackmillionaire.com/oktave/videos/video1.jpg',
  },
  {
    id: 2,
    url: 'https://fullstackmillionaire.com/oktave/videos/video2.jpg',
  },
]
