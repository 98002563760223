import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container } from '~/components'

function Settings() {
  return (
    <Container>
      <h1>Settings</h1>
    </Container>
  )
}

export default withAnalytics(Settings, {})
