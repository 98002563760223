import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container } from '~/components'

function Venues() {
  return (
    <Container>
      <h1>Venues</h1>
    </Container>
  )
}

export default withAnalytics(Venues, {})
