import styled from 'styled-components'
// import PerfectScrollbar from 'react-perfect-scrollbar'

// display: grid;
//         grid-template-columns: fit-content(150px)
//                                fit-content(250px)
//                                fit-content(350px) 1.5fr;

export const Top = styled.div`
  h1 {
    margin-bottom: 40px;
  }
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* flex: 0.8; */
  justify-content: center;
  margin-top: 32px;
`
