import styled from 'styled-components'
import { motion } from 'framer-motion'
import { lighten } from 'polished'

import { theme } from '~/constants'

export const Background = styled(motion.div)`
  background: ${theme.color.dark};
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const ModalItem = styled(motion.div)`
  background: ${lighten(0.02, theme.color.dark)};
  border: 1px solid ${theme.color.border};
  border-radius: 4px;
  padding: 24px 24px 32px;
  width: 600px;
  position: fixed;
  left: 0;
  right: 0;
  margin: -100px auto 0;
  z-index: 2;
`

export const ModalTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
