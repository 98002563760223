import React from 'react'
import PropTypes from 'prop-types'

import { Space } from './styles'

const Spacer = ({ h, w }) => {
  return <Space h={h} w={w} />
}

export default Spacer

Spacer.propTypes = {
  h: PropTypes.number,
  w: PropTypes.number,
}
Spacer.defaultProps = {
  h: 0,
  w: 0,
}
