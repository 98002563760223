import React from 'react'
import PropTypes from 'prop-types'

const VenuesIcon = ({ props, active }) => {
  return (
    <>
      {active ? (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M22.53 1.001l.172.013a1.5 1.5 0 011.183.91c.224.537.114 1.146-.315 1.616l-.113.114-.07.054-.15.097-.16.08-1.863.774 1.915-.611.088-.02.177-.023.088-.004.174.008.171.027a1.5 1.5 0 011.102 1.007c.177.555.016 1.152-.45 1.584l-.124.104-.073.048-.158.084-.167.066-2.24.717c.128.371.215.757.256 1.155l.024.365.003.167v6.111c0 4.05-4.925 7.334-11 7.334S0 19.495 0 15.444V9.32l.005-.2.015-.242C.125 7.696.637 6.625 1.44 5.7l.216-.238C3.598 3.383 7.056 2 11 2c2.205 0 4.259.433 5.98 1.177l5.027-2.092.086-.027.174-.039.087-.01L22.53 1zM11 16.667c-.413 0-.82-.015-1.22-.045v4.105a14.714 14.714 0 002.445 0v-4.105c-.402.03-.811.045-1.225.045zm-7.112-1.739v3.727c.364.28.773.543 1.223.78v-3.907a11.946 11.946 0 01-1.223-.6zm13.001.6v3.906c.45-.236.86-.498 1.223-.78v-3.726c-.386.218-.794.419-1.223.6zM11 4C6.709 4 3.046 5.947 2.19 8.287 2.064 8.63 2 8.98 2 9.333c0 2.734 3.899 5.334 9 5.334s9-2.6 9-5.334a2.93 2.93 0 00-.141-.904 3.503 3.503 0 00-.06-.171l-.944.302c.095.254.145.513.145.773 0 2.178-3.466 4.248-8 4.248s-8-2.07-8-4.248c0-2.177 3.466-4.248 8-4.248.432 0 .854.019 1.265.055l1.883-.784a13.856 13.856 0 00-2.853-.353L11 4zm12.523 1l-.087.004-.088.02-12.5 4-.084.035-.073.048a.5.5 0 00.2.881l.086.012.087-.004.088-.02 12.5-4 .084-.035.073-.048a.5.5 0 00-.119-.855l-.081-.026L23.523 5zm-1.042-3l-.087.011-.086.027-12 5-.08.042-.07.054a.5.5 0 00.275.862L10.52 8l.087-.011.086-.027 12-5 .08-.042.07-.054a.5.5 0 00-.192-.843l-.083-.019L22.48 2z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M11 2c.2 0 .399.004.598.011 2.19.078 4.209.583 5.875 1.392l-2.687 1.119a13.67 13.67 0 00-3.491-.519L11 4C6.71 4 3.047 5.946 2.19 8.285 2.065 8.63 2 8.98 2 9.333c0 2.734 3.899 5.334 9 5.334s9-2.6 9-5.334c0-.101-.005-.203-.016-.304l-.028-.203a3.175 3.175 0 00-.097-.397 3.538 3.538 0 00-.22-.539l1.932-.618c.213.492.351 1.012.405 1.557l.021.337.003.167v6.111c0 4.05-4.925 7.334-11 7.334S0 19.495 0 15.444V9.321l.005-.2.015-.243C.136 7.57.75 6.4 1.705 5.41c1.851-1.945 5.037-3.269 8.698-3.4C10.6 2.005 10.8 2 11 2zM6.11 15.904v3.989a12.9 12.9 0 003.67.834v-4.105a15.251 15.251 0 01-3.67-.718zm6.115.718v4.105a12.898 12.898 0 003.664-.834v-3.989c-.985.327-2.054.557-3.179.675l-.485.043zm-10.223-3.07L2 15.444c0 1.45 1.097 2.863 2.888 3.87V15.43c-1.001-.447-1.887-1-2.619-1.636l-.267-.243zm15.11 1.88v3.881c1.724-.969 2.804-2.313 2.883-3.705l.005-.164V13.55c-.694.657-1.545 1.235-2.518 1.709l-.37.172zM23.523 5l.086.012a.5.5 0 01.2.881l-.073.048-.084.035-12.5 4-.088.02-.087.004a.5.5 0 01-.286-.893l.073-.048.084-.035 12.5-4 .088-.02.087-.004zm-1.042-3l.086.004a.5.5 0 01.274.862l-.069.054-.08.042-12 5-.086.027-.087.01a.5.5 0 01-.36-.865l.069-.054.08-.042 12-5 .086-.027.087-.01zm-2.206 3.376l-2.226.712a7.931 7.931 0 00-.488-.34l2.152-.896c.132.112.26.227.382.345l.18.179z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </>
  )
}

export default VenuesIcon

VenuesIcon.propTypes = {
  props: PropTypes.string,
  active: PropTypes.bool,
}

VenuesIcon.defaultProps = {
  props: null,
  active: false,
}
