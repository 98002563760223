import React from 'react'
import PropTypes from 'prop-types'

const ShareIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M13 4c0-.852.986-1.297 1.623-.783l.084.076 8 8a1 1 0 01.083 1.32l-.083.094-8 8c-.602.603-1.614.22-1.701-.593L13 20v-4.136l-.094-.005c-2.269-.066-5.816 1.394-10.54 4.442l-.813.532C.81 21.327-.158 20.674.02 19.8 1.357 13.258 5.522 9.36 12.34 8.215l.381-.061.279-.041V4zm2 2.415V9a1 1 0 01-.898.995c-5.765.59-9.483 3.03-11.282 7.4l-.136.343.583-.35c4.538-2.693 8.023-3.86 10.539-3.448l.233.043.203.047a1 1 0 01.752.857L15 15v2.584L20.585 12 15 6.415z"
        fill="#949BAF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default ShareIcon

ShareIcon.propTypes = {
  props: PropTypes.string,
}

ShareIcon.defaultProps = {
  props: null,
}
