import styled from 'styled-components'
import { theme } from '~/constants'

export const Section = styled.section`
  text-align: center;
  img {
    margin: 40px 0;
    aspect-ratio: 1;
    width: 100%;
  }

  h1 {
    margin-bottom: 10px;
  }

  p {
    line-height: 28px;
  }

  @media only screen and (max-width: 991px) {
  }
`

export const Price = styled.div`
  border-radius: 24px;
  background: ${theme.color.darkGray};
  padding: 24px;
  margin: 16px;
  flex-grow: 1;
  position: relative;
  border-width: ${props => (props.border ? '6px' : 0)};
  border-color: ${theme.color.primary};
  border-style: solid;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  small {
    text-align: center;
  }

  h3 {
    margin: 8px 0 0;
  }

  h1 {
    margin: 8px 0;
  }

  h5 {
    margin: 8px 0 16px;
  }

  p {
    line-height: 36px;
  }

  button {
    margin: 24px 0 16px;
  }

  @media only screen and (max-width: 991px) {
    margin: 8px;
  }
`

export const PriceTag = styled.small`
  letter-spacing: 2px;
  font-size: 10px;
  text-transform: uppercase;
  padding: 8px 16px;
  background: ${theme.color.primary};
  border-radius: 8px;
  position: absolute;
  top: -16px;
  left: 35%;
  right: 35%;
`

export const PriceSection = styled.section`
  margin-top: 16px;
  display: flex;
  img {
    margin: 40px 0;
    aspect-ratio: 1;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
