import styled from 'styled-components'
import { lighten } from 'polished'
// import PerfectScrollbar from 'react-perfect-scrollbar'

import { theme } from '~/constants'

export const ChatBox = styled.div`
  display: flex;
  bottom: 0;
  left: -60px;
  right: 0;
  padding: 16px;
  position: absolute;
  background-color: ${lighten(0.02, theme.color.dark)};
  border-top: 1px ${theme.color.darkGray} solid;
  padding-bottom: 32px;

  input {
    width: 100%;
    border: none;
    padding-left: 16px;
    border-radius: 30px;
    color: ${theme.color.dark};
    margin-right: 16px;

    ::placeholder {
      color: ${theme.color.gray};
    }
  }

  @media only screen and (max-width: 1144px) {
    left: -50px;
    right: -50px;
  }

  @media only screen and (max-width: 991px) {
    left: -30px;
    right: -30px;
  }

  @media only screen and (max-width: 768px) {
    left: -20px;
    right: -20px;
  }

  @media only screen and (max-width: 420px) {
    left: -20px;
    right: -20px;
  }
`
