import styled from 'styled-components'

export const Top = styled.div`
  h1 {
    margin-bottom: 40px;
  }
`

export const AddItem = styled.div`
  margin-right: 8px;
`

export const AvatarItem = styled.div`
  text-align: center;
  font-size: calc(14px + 0.2vw);
  margin: 0 8px 8px;
`

export const BandItem = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    p {
      font-size: calc(16px + 0.2vw);
      font-weight: bold;
    }
    small {
      font-size: calc(14px + 0.2vw);
    }
  }
`
