import styled from 'styled-components'

import { theme } from '~/constants'

export const Line = styled.div`
  margin-top: ${props => props.top || 0}px;
  margin-bottom: ${props => props.bottom || 16}px;
  border-bottom-color: ${theme.color.darkGray};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`

export const SeparatorHeader = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: ${props => props.top}px;
  margin-bottom: ${props => props.bottom}px;

  small {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 8px;
    letter-spacing: 1.5px;
    color: ${theme.color.gray};
  }
`
