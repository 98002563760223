import styled from 'styled-components'
import { lighten } from 'polished'

import { theme } from '~/constants'

export const PreviewItem = styled.div`
  display: flex;
  /* align-items: center; */

  img {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

    span {
      color: ${theme.color.primary};
    }

    p {
      font-size: calc(14px + 0.2vw);
      line-height: 26px;
    }
  }
`

export const ImageContainer = styled.div`
  display: block;

  label {
    width: 100%;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: none;
    transition: background 0.2s ease-in-out;
    cursor: pointer;

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    &:hover {
      background: ${lighten(0.03, theme.color.dark)};
    }
  }
`
