import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container, Button, Separator } from '~/components'

import { Section, Price, PriceTag, PriceSection } from './styles'
import { prices } from '~/assets/copy'
// import bluredPrices from '~/assets/images/pricing.png'

function Pricing() {
  function handleSubmit() {
    console.tron.log('submit')
  }

  const PriceList = () => {
    return prices.map((price, index) => (
      <Price
        key={price.id}
        go={handleSubmit}
        border={index === 1}
        bg={price.bg}
      >
        {index === 1 && <PriceTag>Best</PriceTag>}
        <div>
          <h3>{price.title}</h3>
          <h1>${price.price}</h1>
          <h5>{price.tag}</h5>
          <Separator line />
          <p>{price.text}</p>
          {price.features.map(feature => (
            <p>{feature.title}</p>
          ))}
        </div>
        <Button preset={index !== 1 ? 'secondary' : 'primary'}>
          Get Started
        </Button>
      </Price>
    ))
  }
  return (
    <Container>
      <Section>
        <h1>Pricing</h1>
        <p>For Bands & Teams</p>
      </Section>
      <PriceSection>
        {/* <img src={bluredPrices} alt="" /> */}
        <PriceList />
      </PriceSection>
    </Container>
  )
}

export default withAnalytics(Pricing, {})
