import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

function Terms() {
  return (
    <div>
      <h1>Terms</h1>
    </div>
  )
}

export default withAnalytics(Terms, {})
