import React from 'react'
import PropTypes from 'prop-types'

const SettingsIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M19 3a1 1 0 011 1v11h1a1 1 0 010 2h-1v3a1 1 0 01-2 0v-3h-1a1 1 0 010-2h1V4a1 1 0 011-1zm-7 0a1 1 0 011 1v3h1a1 1 0 010 2h-1v11a1 1 0 01-2 0V9h-1a1 1 0 110-2h1V4a1 1 0 011-1zM5 3a1 1 0 011 1v11h1a1 1 0 010 2H6v3a1 1 0 01-2 0v-3H3a1 1 0 010-2h1V4a1 1 0 011-1z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SettingsIcon

SettingsIcon.propTypes = {
  props: PropTypes.string,
}

SettingsIcon.defaultProps = {
  props: null,
}
