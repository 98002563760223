import React from 'react'
import PropTypes from 'prop-types'
import { Container, FormInput } from './styles'

const Input = ({ label, name, type, placeholder, ...rest }) => {
  return (
    <Container>
      <FormInput
        label={label}
        name={name}
        type={type}
        placeholder={placeholder}
        {...rest}
      />
    </Container>
  )
}

export default Input

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
}

Input.defaultProps = {
  label: '',
  name: 'name',
  type: null,
  placeholder: 'Placeholder',
}
