import styled from 'styled-components'
import { darken } from 'polished'

import { theme } from '~/constants'

import Button from '../Button'

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  flex: 0.15;
  padding: 40px 60px 20px;
  border-right: 1px solid ${theme.color.border};

  /* transform: ${({ open }) => (open ? 'translateX(-70%)' : 'translateX(0)')};
  transition: transform 0.3s ease-in-out; */

  position: sticky;
  top: 0;

  @media only screen and (max-width: 1144px) {
    padding: 40px 30px 20px;
    align-items: center;
  }

  @media only screen and (max-width: 991px) {
    padding: 40px 20px 20px;
    /* min-width: 110px; */
  }

  @media only screen and (max-width: 768px) {
    padding: 40px 10px 20px;
  }
`

export const Navbar = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  height: 100%;

  li {
    list-style: none;
    display: flex;
  }

  li:last-child {
    margin: 40px 16px;

    button {
      flex: 1;
    }
  }

  @media only screen and (max-width: 1144px) {
    li:last-child {
      display: none;
    }
    li {
      span {
        display: none;
      }
      a {
        justify-content: center;
      }
    }
  }

  @media only screen and (max-width: 991px) {
  }

  @media only screen and (max-width: 768px) {
  }
`

export const NavDrawer = styled.ul`
  display: none;
  z-index: 1;
  @media only screen and (max-width: 991px) {
    display: flex;
  }
`

export const NavButtonLink = styled(Button)`
  display: block;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  background: ${theme.color.primary};
  letter-spacing: 0.5px;
  padding: 16px 36px;
  border-radius: 30px;
  font-size: calc(14px + 0.1vw);

  &:hover {
    background: ${darken(0.1, theme.color.primary)};
  }
`
