import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { theme, presets } from '~/constants'

// eslint-disable-next-line
export const Button = styled(motion.button)`
  color: ${theme.color.light} !important;
  text-decoration: none;
  transition: background 0.2s;
  /* font-size: 14px; */
  text-transform: uppercase;
  background: none;
  letter-spacing: 0.5px;
  padding: 16px 36px;
  border-radius: 30px;
  font-size: calc(14px + 0.2vw);
  border: none;
  font-weight: 500;

  ${props => presets[props.preset || 'default']};
  ${props => props.active && presets.active};
  ${props => props.selected && presets.selected};
`

export const NavLink = styled(Link)`
  color: ${theme.color.light} !important;
  text-decoration: none;
  transition: all 0.2s;
  /* font-size: 14px; */
  text-transform: uppercase;
  background: none;
  letter-spacing: 0.5px;
  padding: 16px 36px;
  border-radius: 30px;
  font-size: calc(14px + 0.2vw);
  border: none;
  font-weight: 500;

  ${props => presets[props.preset || 'default']};
  ${props => props.active && presets.active};
  ${props => props.selected && presets.selected};
`
