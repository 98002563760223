import React from 'react'
import PropTypes from 'prop-types'

import { Line, SeparatorHeader } from './styles'

const Separator = ({ line, top, bottom, label, children }) => {
  return (
    <>
      <SeparatorHeader top={top} bottom={bottom}>
        {label && <small>{label}</small>}
        {children && <div>{children}</div>}
      </SeparatorHeader>
      {line && <Line />}
    </>
  )
}

export default Separator

Separator.propTypes = {
  line: PropTypes.bool,
  top: PropTypes.number,
  bottom: PropTypes.number,
  label: PropTypes.string,
  children: PropTypes.element,
}

Separator.defaultProps = {
  line: false,
  top: 0,
  bottom: 0,
  label: null,
  children: null,
}
