import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Route from './Route'

import {
  About,
  Main,
  Pricing,
  ResetPassword,
  SignIn,
  SignUp,
  SignUpBeta,
  Terms,
  Policy,
  Feed,
  Musicians,
  Invitations,
  Bands,
  BandProfile,
  Profile,
  Venues,
  Calendar,
  Inbox,
  Promotions,
  Upgrade,
  Settings,
} from '~/pages'

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Main} />
      {/* Auth Routes */}
      <Route path="/login" component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/beta" component={SignUpBeta} />

      {/* Landing Routes */}
      <Route path="/about" component={About} />
      <Route path="/pricing" component={Pricing} />
      <Route path="/reset" component={ResetPassword} />
      <Route path="/terms" component={Terms} />
      <Route path="/policy" component={Policy} />

      {/* App Routes */}
      <Route path="/feed" authenticated component={Feed} />
      <Route path="/invitations" authenticated component={Invitations} />
      <Route path="/musicians" authenticated component={Musicians} />
      <Route path="/bands" exact authenticated component={Bands} />
      <Route path="/bands/:id" strict authenticated component={BandProfile} />
      <Route path="/profile" authenticated component={Profile} />
      <Route path="/venues" authenticated component={Venues} />
      <Route path="/calendar" authenticated component={Calendar} />
      <Route path="/promotions" authenticated component={Promotions} />
      <Route path="/inbox" authenticated component={Inbox} />
      <Route path="/upgrade" authenticated component={Upgrade} />
      <Route path="/settings" authenticated component={Settings} />

      {/* 404 Route */}
      {/* <Route render={() => <Redirect to="/" />} /> */}
      <Route component={() => <Redirect to="/" />} />
    </Switch>
  )
}
