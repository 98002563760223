import React from 'react'

import {
  Container,
  Promotion,
  Date,
  DateItem,
  DateContent,
  Description,
  Participants,
  EventType,
  Event,
} from './styles'
import { Avatar, Button, Separator } from '~/components'
import { MoreIcon, AddIcon, StarIcon, TicketIcon } from '~/assets/icons'

const FeedSidebar = ({ data }) => (
  <Container>
    <Promotion>
      <strong>Overview</strong>
      <Separator line label="Upcoming event" top={24} />
      <DateItem>
        <Date>
          <h4>3</h4>
          <small>Okt</small>
        </Date>
        <DateContent>
          <p>Oktave Release</p>
          <small>19:00 - 21:00</small>
        </DateContent>
        <Button preset="icon">
          <MoreIcon />
        </Button>
      </DateItem>
      <Description>description</Description>
      <Participants>
        <Button preset="avatar">
          <AddIcon size={50} />
        </Button>
        <Button preset="avatar">
          <Avatar data={data} size={50} />
        </Button>
      </Participants>

      <Separator label="Event type" top={16} />
      <EventType>
        <Event type="concert">
          <StarIcon />
          <small>Concert</small>
        </Event>
        <Event type="concert">
          <TicketIcon />
          <small>Private</small>
        </Event>
      </EventType>
      <Button preset="icon">Apply To Play</Button>
    </Promotion>
  </Container>
)

export default FeedSidebar
