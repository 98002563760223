import React from 'react'
// import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
// import { parseISO, formatDistance } from 'date-fns'

// import api from '~/services/api'

import { NotificationIcon } from '~/assets/icons'

import { Badge, Container, Scroll, List, NotificationItem } from './styles'
import Avatar from '../Avatar'
import Button from '../Button'

const Notifications = () => {
  const [open, setOpen] = React.useState(false)
  // const [notifications, setNotifications] = React.useState([])

  // React.useEffect(() => {
  //   async function loadNotifications() {
  //     const response = await api.get('notifications')

  //     const data = response.data.map(notification => ({
  //       ...notification,
  //       timeDistance: formatDistance(
  //         parseISO(notification.created_at),
  //         new Date(),
  //         { addSuffix: true }
  //       ),
  //     }))
  //     setNotifications(data)
  //   }

  //   loadNotifications()
  // }, [])

  function handleNotification() {
    setOpen(!open)
  }

  const NotificationsList = () => (
    <List open={open}>
      <Scroll>
        <NotificationItem>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>
        <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>
        <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>
        <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>
        <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>
        <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>
        <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>
        <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>

        <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
          </div>
        </NotificationItem>
        {/* <NotificationItem unread>
          <Avatar />
          <div>
            <p>
              <strong>Riot</strong> Practice was canceled
            </p>
            <time>1 minute ago</time>
            <button type="button">mark as read</button>
          </div>
        </NotificationItem> */}
      </Scroll>
      <button type="button">See all</button>
    </List>
  )

  return (
    <Container>
      <Badge hasUnread>
        <Button preset="icon" onClick={handleNotification}>
          <NotificationIcon />
        </Button>
      </Badge>

      <NotificationsList />
    </Container>
  )
}

export default Notifications

// Notifications.propTypes = {
//   url: PropTypes.string,
//   size: PropTypes.number,
// }

// Notifications.defaultProps = {
//   url: null,
//   size: 40,
// }
