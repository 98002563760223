import React from 'react'
import PropTypes from 'prop-types'

import api from '~/services/api'

import { Block, Button } from '~/components'
import { MoreIcon } from '~/assets/icons'
import { Options } from './styles'

const More = ({ id }) => {
  const [open, setOpen] = React.useState()

  async function deletePost() {
    await api.delete(`/files/${id}`)
    setOpen(false)
  }

  function toggleOptions() {
    setOpen(!open)
  }

  return (
    <Block
      style={{
        position: 'relative',
        flex: 0.05,
      }}
    >
      <Button preset="icon" onClick={toggleOptions} selected={!!open}>
        <MoreIcon />
      </Button>
      <Options open={open}>
        {/* <Button>Edit</Button> */}
        <Button onClick={deletePost}>Delete</Button>
      </Options>
    </Block>
  )
}

export default More

More.propTypes = {
  id: PropTypes.number.isRequired,
}
