import React from 'react'
import PropTypes from 'prop-types'

const MenuIcon = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M11 18a1 1 0 010 2H5a1 1 0 010-2h6zm4-7a1 1 0 010 2H5a1 1 0 010-2h10zm4-7a1 1 0 010 2H5a1 1 0 110-2h14z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default MenuIcon

MenuIcon.propTypes = {
  props: PropTypes.string,
}

MenuIcon.defaultProps = {
  props: null,
}
