import React from 'react'
import PropTypes from 'prop-types'

const StarIcon = ({ props, fill }) => {
  return (
    <>
      {fill ? (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M12.627 1.557l2.356 6.906 7.362.084c.633.007.895.806.387 1.18l-5.906 4.352 2.194 6.957c.188.599-.497 1.092-1.015.73L12 17.548l-6.005 4.215c-.517.363-1.203-.13-1.015-.729l2.194-6.957-5.906-4.352c-.508-.374-.246-1.173.387-1.18l7.362-.084 2.356-6.906c.203-.593 1.052-.593 1.254.001z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M12.627 1.557l2.356 6.906 7.362.084c.633.007.895.806.387 1.18l-5.906 4.352 2.194 6.957c.188.599-.497 1.092-1.015.73L12 17.548l-6.005 4.215c-.517.363-1.203-.13-1.015-.729l2.194-6.957-5.906-4.352c-.508-.374-.246-1.173.387-1.18l7.362-.084 2.356-6.906c.203-.593 1.052-.593 1.254.001zM12 4.364l-1.906 5.585-1.06.013-4.843.055 4.736 3.49-.323 1.022-1.446 4.586 4.843-3.398.861.605 3.98 2.793-1.769-5.608.863-.636 3.873-2.853-5.903-.068L12 4.364z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </>
  )
}

export default StarIcon

StarIcon.propTypes = {
  props: PropTypes.string,
  fill: PropTypes.bool,
}

StarIcon.defaultProps = {
  props: '',
  fill: false,
}
