import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex: 1;
`

export const Top = styled.div`
  h1 {
    margin-bottom: 40px;
  }
`

export const FeedContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.6;
  flex-grow: 1;
  padding-right: 60px;

  @media only screen and (max-width: 1144px) {
    padding-right: 20px;
  }

  @media only screen and (max-width: 991px) {
    /* flex-grow: 1; */
    padding-right: 0;
  }

  @media only screen and (max-width: 768px) {
  }
`
