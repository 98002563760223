import styled from 'styled-components'
import { theme } from '~/constants'

export const Container = styled.div`
  text-align: left;
  margin: 8px 0;

  span {
    font-size: 12px;
    font-weight: 500;
    color: ${theme.color.primary};
  }

  label {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.5px;
    color: ${theme.color.gray};
  }
`
