import React from 'react'
import GoogleAnalytics from 'react-ga'

GoogleAnalytics.initialize('UA-154855239-1')

export const withAnalytics = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    })
    GoogleAnalytics.pageview(page)
  }

  const HOC = props => {
    // eslint-disable-next-line
    React.useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname, // eslint-disable-line
    ])

    return <WrappedComponent {...props} />
  }

  return HOC
}

// withTracker.propTypes = {
//   location: PropTypes.shape({
//     pathname: PropTypes.string,
//   }).isRequired,
// }
