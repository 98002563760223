import React from 'react'
import PropTypes from 'prop-types'

import { Header, Sidebar } from '~/components'
import { AuthContainer, Wrap } from '../styles'

export default function AuthLayout({ children, auth }) {
  return (
    <Wrap>
      <Sidebar />
      <AuthContainer>
        <Header auth />
        {children}
      </AuthContainer>
    </Wrap>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
}
