import React from 'react'
import PropTypes from 'prop-types'

const FeedIcon = ({ props, active }) => {
  return (
    <>
      {active ? (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M14.007.76l8 7.104c.632.561.993 1.36.993 2.2v10.963c0 1.09-.895 1.973-2 1.973h-4c-1.105 0-2-.884-2-1.973v-5.42a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v5.42C9 22.117 8.105 23 7 23H3c-1.105 0-2-.884-2-1.973V10.064c0-.84.361-1.639.993-2.2l8-7.104a3.03 3.03 0 014.014 0zM12.04 5.238h-.08a2.96 2.96 0 00-.174 5.915l.174.005h.08a2.96 2.96 0 00.174-5.915l-.174-.005z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M14.007.76l8 7.104c.632.561.993 1.36.993 2.2v10.963c0 1.09-.895 1.973-2 1.973h-5c-1.105 0-2-.884-2-1.973v-5.42a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v5.42C10 22.117 9.105 23 8 23H3c-1.105 0-2-.884-2-1.973V10.064c0-.84.361-1.639.993-2.2l8-7.104a3.03 3.03 0 014.014 0zM11.33 2.227l-8 7.104a.98.98 0 00-.331.733v10.463a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-5.42c0-1.09.895-1.974 2-1.974h4c1.104 0 2 .884 2 1.974v5.42a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V10.064c0-.28-.12-.546-.331-.733l-8-7.104a1.01 1.01 0 00-1.338 0zm.696 3.998a1.973 1.973 0 01.147 3.941l-.2.006a1.973 1.973 0 01-.148-3.941l.2-.006z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </>
  )
}

export default FeedIcon

FeedIcon.propTypes = {
  props: PropTypes.string,
  active: PropTypes.bool,
}

FeedIcon.defaultProps = {
  props: null,
  active: false,
}
