import React from 'react'
import { Router } from 'react-router-dom'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './config/ReactotronConfig'

import { persistor, store } from './store'
import history from './services/history'

import Routes from './navigation'

// import { FBChat } from './components'
import GlobalStyle from './constants/global'

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          {/* <FBChat /> */}
          <ToastContainer autoclose={2000} />
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
