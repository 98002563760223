import React from 'react'
import PropTypes from 'prop-types'

const CameraIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M5 6h.586a1 1 0 00.707-.293l.83-.83c.366-.366.505-.479.678-.587.172-.107.347-.18.545-.226l.133-.026C8.64 4.01 8.838 4 9.24 4h5.522c.516 0 .695.019.893.064.198.046.373.119.545.226l.113.076c.114.081.238.19.45.398l.945.943a1 1 0 00.707.293H19a4 4 0 014 4v7.872c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H6.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512c-.33-.618-.514-1.23-.532-2.81L1 10a4 4 0 014-4zm10.168.008l-.056-.004H8.888a.972.972 0 00-.056.004l-.113.104-1.305 1.302A2 2 0 016 8H5a2 2 0 00-2 2v7.872c0 1.324.078 1.727.298 2.138.162.304.388.53.692.692l.155.076c.345.15.76.213 1.745.221L17.872 21c1.324 0 1.727-.078 2.138-.298.304-.162.53-.388.692-.692l.076-.155c.15-.345.213-.76.221-1.745L21 10a2 2 0 00-2-2h-1a2 2 0 01-1.414-.586L15.21 6.045a.972.972 0 00-.043-.037zM12 18.5a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-2a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM6 10a1 1 0 110 2 1 1 0 010-2zm9-9a1 1 0 010 2H9a1 1 0 110-2h6z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default CameraIcon

CameraIcon.propTypes = {
  props: PropTypes.string,
}

CameraIcon.defaultProps = {
  props: null,
}
