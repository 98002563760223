import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// import { getBandsRequest } from '~/store/modules/bands/actions'
// import api from '~/services/api'

import { withAnalytics } from '~/hocs/withAnalytics'

import CreateBand from './CreateBand'

import {
  Container,
  Block,
  Avatar,
  Button,
  Separator,
  // Spacer,
} from '~/components'

// import { bands } from '~/database'

import {
  // AvatarItem,
  // AddItem,
  BandItem,
} from './styles'

function Bands() {
  const bands = useSelector(state => state.bands.data)
  const dispatch = useDispatch()
  console.log(bands)
  // React.useEffect(() => {
  //   async function getBands() {
  //     dispatch(getBandsRequest())
  //   }
  //   getBands()
  // }, [dispatch])

  return (
    <Container>
      <Block flex={0.05}>
        <h1>Bands</h1>
        <Block direction="row" marginTop={40}>
          <CreateBand />
          {bands &&
            bands.length > 0 &&
            bands.map(band => (
              <Button key={band.id} to={`/bands/${band.id}`} preset="avatar">
                <Avatar data={band.avatar} size={120} />
              </Button>
            ))}
        </Block>
      </Block>
      <Block marginTop={20}>
        <Separator line label="All Bands" />
        {bands &&
          bands.length > 0 &&
          bands.map(band => (
            <Button key={band.id} preset="transparent" to={`/bands/${band.id}`}>
              <BandItem>
                <Avatar data={band.avatar} size={50} />
                <div>
                  <p>{band.name}</p>
                  <small>{band.name}</small>
                </div>
              </BandItem>
            </Button>
          ))}
      </Block>
    </Container>
  )
}

export default withAnalytics(Bands, {})
