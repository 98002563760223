import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'oktave',
      storage,
      whitelist: ['auth', 'user', 'bands'],
    },
    reducers
  )

  return persistedReducer
}
