import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { signOut } from '~/store/modules/auth/actions'

import {
  BandsIcon,
  CalendarIcon,
  FeedIcon,
  MusiciansIcon,
  PromotionsIcon,
  InboxIcon,
  VenuesIcon,
} from '~/assets/icons'

// import { NavButtonLink } from '../styles'
import Button from '../../Button'

const NavLinks = () => {
  const dispatch = useDispatch()
  const [location, setLocation] = React.useState('')

  // eslint-disable-next-line
  function NavLinkActive({ children, to }) {
    const match = useRouteMatch(`${to}`)
    if (match) setLocation(match.path)
    return (
      <>
        <Button preset="sidebar" active={match && match.isExact} to={to}>
          {children}
        </Button>
      </>
    )
  }

  function handleSignOut() {
    dispatch(signOut())
  }

  return (
    <>
      <li>
        <NavLinkActive to="/feed">
          <FeedIcon active={location === '/feed'} />
          <span>Feed</span>
        </NavLinkActive>
      </li>

      <li>
        <NavLinkActive to="/musicians">
          <MusiciansIcon active={location === '/musicians'} />
          <span>Musicians</span>
        </NavLinkActive>
      </li>

      <li>
        <NavLinkActive to="/bands">
          <BandsIcon active={location === '/bands'} />
          <span>Bands</span>
        </NavLinkActive>
      </li>

      {/* <li>
        <NavLinkActive to="/inbox">
          <InboxIcon active={location === '/inbox'} />
          <span>Inbox</span>
        </NavLinkActive>
      </li> */}

      <li>
        <NavLinkActive to="/calendar">
          <CalendarIcon active={location === '/calendar'} />
          <span>Calendar</span>
        </NavLinkActive>
      </li>

      <li>
        <NavLinkActive to="/venues">
          <VenuesIcon active={location === '/venues'} />
          <span>Venues</span>
        </NavLinkActive>
      </li>

      {/* <li>
        <NavLinkActive to="/promotions">
          <PromotionsIcon active={location === '/promotions'} />
          <span>Promotions</span>
        </NavLinkActive>
      </li> */}

      {/* <li>
        <NavButtonLink to="/upgrade">Upgrade</NavButtonLink>
      </li> */}
      <li>
        <Button preset="default" onClick={handleSignOut}>
          Sign out
        </Button>
      </li>
    </>
  )
}

export default NavLinks
