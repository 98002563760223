import styled, { css } from 'styled-components'
import { lighten, darken } from 'polished'
import { theme } from '~/constants'

import lines from '~/assets/images/lines.svg'

export const Container = styled.div`
  background: ${lighten(0.02, theme.color.dark)};
  border: 1px solid ${theme.color.border};
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  flex: 1;
  overflow: hidden;
`

export const CalendarTop = styled.div`
  outline: 1px solid ${theme.color.border};

  h3 {
    padding: 20px 30px;
  }
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const CalendarNav = styled.div`
  display: flex;
  padding: 36px 30px 0;
  align-items: center;
  justify-content: center;

  h4 {
    margin: 0 16px;
    width: 250px;
    text-align: center;
  }

  button {
    display: flex;
    background: none;
  }
`

export const CalendarHeader = styled.div`
  display: flex;
  margin: 16px 0;

  p {
    display: flex;
    flex: 1;
    opacity: 0.3;
    justify-content: center;
  }
`

export const Month = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`

export const Week = styled.div`
  display: flex;
  flex: 1;
`

export const Day = styled.div`
  display: flex;
  flex: 1;
  outline: 1px solid ${theme.color.border};
  min-width: 100px;
  min-height: 80px;

  p {
    padding: 10px 16px;
  }

  ${props =>
    props.active &&
    css`
      background-color: ${darken(0.01, theme.color.dark)};
      background-image: url(${lines});
      cursor: not-allowed;
      color: #4f4f4f;

      div::before {
        pointer-events: none;
      }
    `}

  ${props =>
    props.active &&
    !props.disabled &&
    css`
      background-color: ${lighten(0.2, theme.color.dark)};
      font-weight: bold;
    `}
`
