import React from 'react'
import {
  // useDispatch,
  useSelector,
} from 'react-redux'
import axios from 'axios'

import { withAnalytics } from '~/hocs/withAnalytics'

import { Container, Input } from '~/components'

function Profile() {
  // const user = useSelector(state => state.user.profile)
  const profile = useSelector(state => state.user.profile)
  const [location, setLocation] = React.useState({})

  React.useEffect(() => {
    async function getUserLocation() {
      // if (profile && profile.location !== )

      const response = await axios.get(
        // 'https://api.ipgeolocationapi.com/geolocate/'
        'https://ipapi.co/json/'
      )
      console.tron.log(response)
      setLocation(response.data)
    }

    getUserLocation()
  }, [])

  // console.tron.log(profile)
  return (
    <Container>
      <h1>Profile</h1>

      <Input
        name="location"
        label="location"
        type="text"
        disabled
        placeholder={`${location.country_capital}, ${location.country}`}
      />
      <Input
        name="username"
        label="username"
        type="text"
        placeholder="johnsson"
      />
      <Input
        name="password"
        label="Password"
        type="password"
        placeholder="**********"
      />
    </Container>
  )
}

export default withAnalytics(Profile, {})
