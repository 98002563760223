import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  flex-direction: ${props => props.direction || 'column'};
  /* white-space: pre-wrap; */
`
export const SignWrap = styled.div`
  margin: 15% 0;

  h1 {
    margin-bottom: 5px;
    font-weight: 800;
    font-size: calc(32px + 0.5vw);
  }

  h5 {
    font-weight: 400;
    margin-bottom: 40px;
  }
`

export const SignCTA = styled(SignWrap)`
    text-align: center};

  h1{
    font-size: calc(30px + 0.5vw);
  }
  
  h5{
    margin-bottom: 40px;
  }
`

export const Image = styled.img`
  min-height: 400px;
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;
`

export const CardImage = styled.img`
  aspect-ratio: 1;
  width: 100%;
`
export const CardSection = styled.section`
  display: flex;
  flex-wrap: wrap;

  div:nth-child(2) {
    margin-top: 120px;
  }

  div:nth-child(3) {
    margin: -120px 0 120px;
  }

  @media only screen and (max-width: 1144px) {
    /* padding: 0 80px; */
  }

  @media only screen and (max-width: 991px) {
    div:nth-child(2) {
      margin: 0;
    }

    div:nth-child(3) {
      margin: 0;
    }
  }

  @media only screen and (max-width: 768px) {
  }
`

const size = 50
export const Card = styled.div`
  background-image: url(${props => props.bg || 'none'});
  background-color: ${props => props.color || 'none'};
  width: ${size}%;
  min-height: ${size * 8}px;
  padding: 90px 65px;

  h5 {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
  }

  p {
    margin-top: 10px;
    line-height: 30px;
  }

  @media only screen and (max-width: 991px) {
    width: ${size * 2}%;
    padding: 90px 45px;
    min-height: ${size * 8}px;

    h2 {
      font-size: calc(26px + 0.4vw);
    }
  }

  @media only screen and (max-width: 768px) {
  }
`
