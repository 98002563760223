import React from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'

import { useField } from '@rocketseat/unform'

import { Container, FormInput } from './styles'

import 'react-datepicker/dist/react-datepicker.css'

const InputDate = ({ label, name, type, placeholder }) => {
  const ref = React.useRef(null)

  const { fieldName, registerField, defaultValue, error } = useField(name)
  const [selected, setSelected] = React.useState(defaultValue)

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.selected',
      clearValue: pickerRef => {
        pickerRef.clear()
      },
    })
    // eslint-disable-next-line
  }, [ref.current, fieldName])

  return (
    <Container>
      <label htmlFor={fieldName}>{label}</label>
      <ReactDatePicker
        ref={ref}
        name={fieldName}
        selected={selected}
        placeholderText={placeholder}
        maxDate={new Date()}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat="dd/MM/yyyy"
        onChange={date => setSelected(date)}
      />
      {error && <span>{error}</span>}
    </Container>
  )
}

export default InputDate

InputDate.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
}

InputDate.defaultProps = {
  label: 'Label',
  name: 'name',
  type: null,
  placeholder: 'Placeholder',
}
