import React from 'react'

import api from '~/services/api'
// import history from '~/services/history'

// import { user } from '~/database'

import PostItem from '../PostItem'
import { PostList } from './styles'

const Posts = () => {
  const [posts, setPosts] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [
    // lastPage,
    setLastPage,
  ] = React.useState(1)
  const [
    // total,
    setTotal,
  ] = React.useState()

  React.useEffect(() => {
    async function getFeed() {
      try {
        const response = await api.get(`/posts?page=${page}`)

        setPosts(response.data.data)
        setLastPage(response.data.lastPage)
        setTotal(response.data.total)
      } catch (err) {
        // console.log(err)
        // force user back if no band was found
        // history.push('/bands')
      }
    }

    getFeed()
  }, []) // eslint-disable-line

  // function loadMorePosts() {
  //   setPage(page + 1)
  // }

  return (
    <PostList>
      {posts.length >= 1 &&
        posts.map(post => <PostItem key={post.id} post={post} />)}
    </PostList>
  )
}

export default Posts
