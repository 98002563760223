import styled from 'styled-components'
import { theme } from '~/constants'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  button {
    flex: 0.3;

    p {
      margin-left: 8px;
      color: ${theme.color.light};
      text-transform: none;
    }

    @media only screen and (max-width: 520px) {
      p {
        display: none;
      }
    }
  }
`
