import React from 'react'
import PropTypes from 'prop-types'

const InboxIcon = ({ props, active }) => {
  return (
    <>
      {active ? (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M17.872 3c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v7.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H6.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512C1.186 18.302 1 17.655 1 15.872V8.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 011.512-1.512C3.698 3.186 4.345 3 6.128 3h11.744zm2.909 3.375a1 1 0 00-1.406-.156L12 11.72l-7.375-5.5a1 1 0 00-1.25 1.562l8 6a1 1 0 001.25 0l8-6a1 1 0 00.156-1.406z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M5.85 3.002L17.871 3c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v7.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H6.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512c-.33-.618-.514-1.23-.532-2.81L1 8.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 011.512-1.512c.617-.33 1.229-.514 2.803-.532zm15.112 3.956l-7.726 6.071a2 2 0 01-2.472 0L3.038 6.96c-.022.241-.034.542-.037.931L3 15.872c0 1.324.078 1.727.298 2.138.162.304.388.53.692.692l.155.076c.345.15.76.213 1.745.221L17.872 19c1.324 0 1.727-.078 2.138-.298.304-.162.53-.388.692-.692l.076-.155c.15-.345.213-.76.221-1.745L21 8.128c0-.508-.011-.88-.038-1.17zM19.35 5H4.65a.3.3 0 00-.185.536L12 11.456l7.535-5.92A.3.3 0 0019.35 5z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </>
  )
}

export default InboxIcon

InboxIcon.propTypes = {
  props: PropTypes.string,
  active: PropTypes.bool,
}

InboxIcon.defaultProps = {
  props: null,
  active: false,
}
