import styled from 'styled-components'
import { lighten } from 'polished'
import { theme } from '~/constants'

export const Post = styled.div`
  background: ${lighten(0.02, theme.color.dark)};
  border: 1px solid ${theme.color.border};
  border-radius: 4px;
  padding: 16px;
  margin-top: 30px;

  transition: background 0.2s;

  & + div {
    margin-top: 30px;
  }

  &:hover {
  }
`

export const PostTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 8px 0 24px;

  button {
    padding: 8px;
  }
`

export const UserInfo = styled.div`
  flex: 1;
  padding: 0 16px;

  p {
    font-weight: bold;
  }

  time {
    font-size: calc(12px + 0.08vw);
    color: ${theme.color.gray};
  }
`

export const PostContent = styled.div`
  p {
    padding: 16px 0;
    line-height: 26px;
  }

  span {
    width: 100% !important;
    height: 500px !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
