import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

function ResetPassword() {
  return (
    <div>
      <h1>ResetPassword</h1>
    </div>
  )
}

export default withAnalytics(ResetPassword, {})
