import React from 'react'
import { Helmet } from 'react-helmet'
import { withAnalytics } from '~/hocs/withAnalytics'

import { theme } from '~/constants'

import { Container } from '~/components'

import { Section, FormContainer } from '../styles'

function SignUpBeta() {
  const [firstName, setFirstName] = React.useState()
  const [lastName, setLastName] = React.useState()
  const [email, setEmail] = React.useState()

  return (
    <Container>
      <Helmet>
        {/* <!-- Begin Mailchimp Signup Form --> */}
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">
          {`
          #mc_embed_signup {
          background: ${theme.color.transparent}; clear:left; font:14px ${theme.font.family}, Helvetica,Arial,sans-serif; }
          .mc-field-group{ width: 100% !important;}
          .indicates-required{margin-right: 0 !important;}
          #mc_embed_signup form{ padding-left: 0 !important;}
          #mc_embed_signup .button {
            transition: all 0.2s;
            background: ${theme.color.primary};
            border-radius: 30px;
            width: 100%;
            padding: 8px 36px;
            height: auto;
            text-transform: uppercase;
            font-weight: 500;
            font-size: calc(14px + 0.2vw);
            letter-spacing: 0.5px;
          }
          #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error{
            font-size: 12px;
            background-color: rgb(255, 255, 255);
            font-weight: 500;
          }
          `}
        </style>

        <script
          type="text/javascript"
          src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
        />
        <script type="text/javascript">{`(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';}(jQuery));var $mcj = jQuery.noConflict(true);`}</script>
        {/* <!--End mc_embed_signup--> */}
      </Helmet>
      <Section>
        {/* <h1>Sign In</h1> */}
        <FormContainer>
          <strong>Sign up for Beta Access</strong>
          <div id="mc_embed_signup">
            <form
              action="https://oktave.us4.list-manage.com/subscribe/post?u=5a16558990c8720a75c614811&amp;id=cb928ceefa"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <div id="mc_embed_signup_scroll">
                <div className="indicates-required">
                  <span className="asterisk">*</span> indicates required
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-FNAME">
                    First Name <span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    value={firstName}
                    defaultValue={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    name="FNAME"
                    className="required"
                    id="mce-FNAME"
                    placeholder="John Johnsson"
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-LNAME">Last Name </label>
                  <input
                    type="text"
                    value={lastName}
                    defaultValue={lastName}
                    onChange={e => setLastName(e.target.value)}
                    name="LNAME"
                    className=""
                    id="mce-LNAME"
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL">
                    Email Address <span className="asterisk">*</span>
                  </label>
                  <input
                    type="email"
                    value={email}
                    defaultValue={email}
                    onChange={e => setEmail(e.target.value)}
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    placeholder="john@johnsson.is"
                  />
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}
                  />
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}
                  />
                </div>
                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_5a16558990c8720a75c614811_cb928ceefa"
                    tabIndex="-1"
                    value=""
                  />
                </div>
                <div style={{ textAlign: 'center', marginBottom: 10 }}>
                  <p>Become a beta tester by signing up.</p>
                </div>

                <div className="clear">
                  <input
                    type="submit"
                    value="Sign Up For Beta"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button"
                  />
                </div>
              </div>
            </form>
          </div>
        </FormContainer>
      </Section>
    </Container>
  )
}

export default withAnalytics(SignUpBeta, {})
