import React from 'react'
import PropTypes from 'prop-types'

const CloseIcon = ({ props }) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M12 11.025l5.265-5.703a1 1 0 011.47 1.356L13.36 12.5l5.374 5.82a1 1 0 11-1.47 1.357L12 13.973l-5.265 5.703a1 1 0 11-1.47-1.356l5.374-5.821-5.374-5.82a1 1 0 111.47-1.357L12 11.025z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default CloseIcon

CloseIcon.propTypes = {
  props: PropTypes.string,
}

CloseIcon.defaultProps = {
  props: null,
}
