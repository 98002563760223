import * as Yup from 'yup'

export const schemaLogin = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const schemaRegister = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  lastname: Yup.string(),
  birthday: Yup.date().required('Birthday is required'),
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password requires minimum 6 characters')
    .required('Password is required'),
})

export const schemaReset = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Email is required'),
})

export const schemaPost = Yup.object().shape({
  file_id: Yup.string(),
  content: Yup.string().required('Content can not be empty'),
})

export const schemaBand = Yup.object().shape({
  file_id: Yup.string(),
  name: Yup.string().required('Band name can not be empty'),
  avatar: Yup.string(),
  images: Yup.string(),
  genres: Yup.string(),
  location: Yup.string(),
  latitude: Yup.string(),
  longitude: Yup.string(),
  about: Yup.string(),
  influences: Yup.string(),
  audio: Yup.string(),
})

// username: Yup.string().required('Username is required'),
