import React from 'react'
import PropTypes from 'prop-types'

const BandsIcon = ({ props, active }) => {
  return (
    <>
      {active ? (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M18 1a3 3 0 013 3v16a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 013-3h12zm-2.083 6.028a.9.9 0 00-1.087-.484l-.106.04-4.295 1.97a1 1 0 00-.575.78l-.008.128v4.297a1.538 1.538 0 101.204 1.797l.02-.135.007-5.49a.5.5 0 01.22-.414l.071-.04 3.118-1.43a.2.2 0 01.276.13l.007.053v3.067a1.538 1.538 0 101.212 1.75l.016-.14.002-5.504a.901.901 0 00-.082-.375z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M18 1a3 3 0 013 3v16a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 013-3h12zm0 2H6a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1zm-2.083 4.028a.901.901 0 01.082.375l-.002 5.505a1.539 1.539 0 11-1.228-1.61V8.23a.2.2 0 00-.283-.182l-3.118 1.429a.5.5 0 00-.291.454l-.008 5.49a1.539 1.539 0 11-1.223-1.662V9.462a1 1 0 01.583-.909l4.295-1.968a.9.9 0 011.193.443z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </>
  )
}

export default BandsIcon

BandsIcon.propTypes = {
  props: PropTypes.string,
  active: PropTypes.bool,
}

BandsIcon.defaultProps = {
  props: null,
  active: false,
}
