import styled from 'styled-components'
import { lighten } from 'polished'
import { Form as Unform, Input } from '@rocketseat/unform'
import { theme } from '~/constants'

export const Form = styled(Unform)``

export const FeedHeader = styled.div`
  background: ${lighten(0.02, theme.color.dark)};
  border: 1px solid ${theme.color.border};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background 0.2s;

  p {
    flex-grow: 1;
    padding: 0 16px;
  }

  button {
    display: flex;
    padding: 8px;
    background: none;
    border-radius: 8px;
    transition: background 0.2s;

    &:hover {
      background: ${lighten(0.2, theme.color.dark)};
      cursor: pointer;
    }
  }

  &:hover {
    background: ${lighten(0.05, theme.color.dark)};
    cursor: pointer;
  }
`

export const CreatePostText = styled.div`
  margin: 20px 0;

  span {
    font-size: 12px;
    margin-bottom: ;
    color: ${theme.color.primary};
  }
`

export const PostInput = styled(Input)`
  width: 100%;
  color: white;
  background: none;
  border: none;
  margin: 0 0 10px;
  padding: 16px;
  border-radius: 8px;
  min-height: 150px;
  border: 1px ${theme.color.darkGray} solid;

  ::placeholder {
    color: ${theme.color.gray};
    opacity: 1;
  }

  :invalid {
    border: 2px ${theme.color.primary} solid;
  }

  :focus {
    border-color: white;
  }
`

export const PostTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 8px 0 24px;

  button {
    display: flex;
    padding: 8px;
    background: none;
    border-radius: 8px;
    transition: background 0.2s;

    &:hover {
      background: ${lighten(0.05, theme.color.dark)};
      cursor: pointer;
    }
  }

  div {
    flex: 1;
    padding: 0 16px;

    p {
      font-weight: bold;
    }

    time {
      font-size: calc(12px + 0.08vw);
      color: ${theme.color.gray};
    }
  }
`
