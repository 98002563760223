import React from 'react'
import {
  startOfWeek,
  format,
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  addDays,
  addMonths,
  subMonths,
} from 'date-fns'

import { ChevronIcon } from '~/assets/icons'
import Button from '../Button'

import {
  Container,
  CalendarTop,
  Content,
  CalendarHeader,
  CalendarNav,
  Month,
  Week,
  Day,
} from './styles'

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = React.useState(new Date())
  const [selectedDate, setSelectedDate] = React.useState(new Date())

  const onDateClick = day => {
    setSelectedDate(day)
  }

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1))
  }

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1))
  }

  function renderHeader() {
    const dateFormat = 'MMMM yyyy'

    return (
      <CalendarNav>
        <Button type="button" onClick={prevMonth}>
          <ChevronIcon size={40} />
        </Button>
        <h4>{format(currentMonth, dateFormat)}</h4>
        <Button type="button" onClick={nextMonth}>
          <ChevronIcon r size={40} />
        </Button>
      </CalendarNav>
    )
  }

  function renderWeekDays() {
    const dateFormat = 'EEE'
    const weekdays = []

    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 })

    for (let i = 0; i < 7; i++) {
      weekdays.push(<p key={i}>{format(addDays(startDate, i), dateFormat)}</p>)
    }

    return <CalendarHeader>{weekdays}</CalendarHeader>
  }

  function renderDays() {
    const monthStart = startOfMonth(currentMonth)
    const monthEnd = endOfMonth(monthStart)
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 })
    const endDate = endOfWeek(monthEnd)
    const currentDay = new Date()

    const dateFormat = 'd'
    const rows = []

    let days = []
    let day = startDate
    let formattedDate = ''

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat)
        const cloneDay = day
        const disabled = !isSameMonth(day, monthStart)
        const selected = isSameDay(day, selectedDate)
        days.push(
          <Day
            disabled={disabled}
            active={selected}
            key={day}
            onClick={() => {
              if (!disabled) onDateClick(cloneDay)
            }}
          >
            <p>
              {formattedDate} {isSameDay(day, currentDay) && 'Today'}
            </p>
          </Day>
        )
        day = addDays(day, 1)
      }
      rows.push(
        <Week className="row" key={day}>
          {days}
        </Week>
      )
      days = []
    }
    return <Month>{rows}</Month>
  }

  return (
    <Container>
      <CalendarTop>
        <h3>Calendar</h3>
      </CalendarTop>

      <Content>
        {renderHeader()}
        {renderWeekDays()}
        {renderDays()}
      </Content>
    </Container>
  )
}

export default Calendar
