import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Select } from '@rocketseat/unform'

import { createBandRequest } from '~/store/modules/bands/actions'

import { schemaBand } from '~/utils'

import {
  Container,
  Block,
  Avatar,
  Button,
  Separator,
  Input,
  Modal,
} from '~/components'
import { AddIcon } from '~/assets/icons'

const CreateBand = () => {
  const options = [
    {
      id: '1',
      title: 'Marrabenta',
    },
    {
      id: '2',
      title: 'Rock',
    },
    {
      id: '3',
      title: 'Punk',
    },
  ]

  const [isVisible, setIsVisible] = React.useState(false)
  const dispatch = useDispatch()

  async function createBand() {
    dispatch(createBandRequest())
  }

  function toggleVisible() {
    setIsVisible(!isVisible)
  }

  return (
    <>
      <Button preset="avatar" onClick={toggleVisible}>
        <AddIcon size={120} />
      </Button>

      <Modal isVisible={!isVisible} toggle={toggleVisible} title="Create Band">
        <Form schema={schemaBand} onSubmit={createBand}>
          <Input name="file_id" placeholder="Image" />
          {/* <Input name="avatar" required /> */}
          <Input name="bandname" placeholder="Awesome Band Name" />
          <Input name="bio" multiline placeholder="Awesome Bio" />
          {/* <Input name="image" placeholder="Awesome Image" /> */}
          {/* <Input name="genres" placeholder="Awesome Genres" /> */}
          <Select name="genres" options={options} />
          {/* <Input name="location" placeholder="Location" /> */}
          <Input
            name="influences"
            label="influences"
            placeholder="Which bands influenceds your band?"
          />

          <Button type="submit" preset="primary">
            Create Band
          </Button>
        </Form>
      </Modal>
    </>
  )
}

export default CreateBand
