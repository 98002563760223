import styled from 'styled-components'
import { Input } from '@rocketseat/unform'
import { theme } from '~/constants'

export const Container = styled.div`
  text-align: left;
  margin: 8px 0;

  span {
    font-size: 12px;
    font-weight: 500;
    color: ${theme.color.primary};
  }

  label {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.5px;
    color: ${theme.color.gray};
  }
`

export const FormInput = styled(Input)`
  background: none;
  width: 100%;
  border-radius: 8px;
  border: solid ${theme.color.darkGray} 1px;
  margin: 10px 0;
  color: ${theme.color.light};
  padding: 16px;
  font-size: calc(14px + 0.2vw);

  :disabled {
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${theme.color.gray};
    opacity: 1;
  }

  :invalid {
    border: 2px ${theme.color.primary} solid;
  }

  :focus {
    border-color: white;
  }

  /* :required {
    border-color: yellow;
  } */
`
