import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container, Block, Button, Avatar } from '~/components'
import { ChatBox } from './styles'
import InboxSidebar from './InboxSidebar'

import { user } from '~/database'

function Inbox() {
  return (
    <Container>
      <Block flex={0.05}>
        <h1>Inbox</h1>
      </Block>
      <Block direction="row">
        <Block>
          <Block>
            <div>
              <Avatar url={user.image} />
            </div>
          </Block>
          <ChatBox flex={0.1}>
            <input placeholder="Type message" />
            <Button preset="secondary">Send</Button>
          </ChatBox>
        </Block>
        <InboxSidebar />
      </Block>
    </Container>
  )
}

export default withAnalytics(Inbox, {})
