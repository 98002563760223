import styled from 'styled-components'
// import PerfectScrollbar from 'react-perfect-scrollbar'

import { theme } from '~/constants'

export const MusicianContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  padding: 0 5px 60px;
  flex: 1 0 21%;

  @media only screen and (max-width: 1144px) {
    padding: 0 10px 60px;
  }
  @media only screen and (max-width: 991px) {
    flex: 1 0 50%;
  }
`

export const MusicianItem = styled.div`
  width: 335px;
  height: 500px;
  background: ${theme.color.dark};
  border-radius: 20px;
  position: relative;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25), 0 10px 30px 0 rgba(0, 0, 0, 0.25);

  overflow: hidden;

  img {
    width: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @media only screen and (max-width: 1180px) {
  }

  @media only screen and (max-width: 991px) {
    width: 305px;
    height: 400px;
  }

  @media only screen and (max-width: 768px) {
  }
  @media only screen and (max-width: 768px) {
  }
`

export const Information = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  position: absolute;
  z-index: 1;
  background: ${theme.color.dark};
  padding: 16px 20px;
  left: 0;
  right: 0;
  bottom: 0;

  div {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div:last-child {
    text-align: right;
    p {
      font-size: 13px;
      opacity: 0.6;
      font-weight: normal;
    }

    small {
      font-size: 14px;
      opacity: 1;
    }
  }

  p {
    font-size: 18px;
    font-weight: bold;
  }

  small {
    font-size: 14px;
    opacity: 0.6;
  }
`

export const Interact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  button {
    width: 50px;
    height: 50px;
    background: ${theme.color.darkGray};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:nth-child(2) {
    width: 60px;
    height: 60px;
    margin: 0 25px;
  }
`

// export const Pass = styled.button`
//   width: 50px;
//   height: 50px;
//   background: ${theme.color.dark};
//   display: flex;
// `
// export const AddContact = styled(Pass)``
// export const Follow = styled.button``

export const MusicianHeader = styled.div`
  display: flex;

  button {
    margin-right: 8px;
  }
`

export const AddItem = styled.div`
  margin-right: 8px;
`
