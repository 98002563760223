import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { darken } from 'polished'

import { theme } from '~/constants'

export const Container = styled.header`
  display: flex;
  min-height: 90px;
  width: 100%;
  align-items: center;
  justify-content: ${props => (props.auth ? 'flex-end' : 'space-between')};
`

export const Nav = styled.ul`
  display: flex;
  padding-top: 20px;

  li {
    list-style: none;
    margin-left: 30px;
  }

  @media only screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: ${darken(0.05, theme.color.dark)};
    z-index: 1;
    transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
    transition: transform 0.3s ease-in-out;
    padding: 80px 0 100px;
    text-align: center;

    li {
      list-style: none;
      margin-left: 0;

      margin-bottom: 40px;
    }

    li:last-child {
      margin-top: 15px;
    }
  }
`

export const AuthNav = styled.ul`
  display: flex;
  padding-top: 20px;
  align-items: center;

  li {
    list-style: none;
    margin: 0 2px;
  }

  li:first-child {
    margin-right: 16px;
  }

  li:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 991px) {
    li:first-child {
      display: none;
    }
  }
`

export const NavDrawer = styled.ul`
  display: none;
  z-index: 1;
  @media only screen and (max-width: 991px) {
    display: flex;
  }
`

export const NavLink = styled(Link)`
  font-weight: bold;
  color: ${theme.color.light};
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: ${darken(0.2, theme.color.light)};
  }
`
export const SimpleLink = styled.a`
  font-weight: bold;
  color: ${theme.color.light};
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: ${darken(0.2, theme.color.light)};
  }
`

export const NavButtonLink = styled(NavLink)`
  font-weight: 500;
  text-transform: uppercase;
  background: ${theme.color.primary};
  letter-spacing: 0.5px;
  padding: 16px 36px;
  border-radius: 30px;
  font-size: calc(14px + 0.1vw);

  &:hover {
    background: ${darken(0.1, theme.color.primary)};
  }
`
