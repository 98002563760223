import React from 'react'
import PropTypes from 'prop-types'

const MusiciansIcon = ({ props, active }) => {
  return (
    <>
      {active ? (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M21.309 1.29l1.4 1.401a.99.99 0 010 1.401l-2.1 2.102a.99.99 0 01-1.402 0l-4.948 4.948c1.226 1.597 1.37 3.3-.029 4.7l-1.351 1.35c-.399 1.94-1.065 3.4-2.035 4.37-2.215 2.216-5.32 1.773-7.75-.656-2.43-2.43-2.872-5.535-.656-7.75.97-.97 2.43-1.636 4.37-2.035l1.35-1.351c1.4-1.399 3.103-1.255 4.7-.03l4.948-4.947a.99.99 0 010-1.401l2.102-2.102a.99.99 0 011.4 0zM9.19 14.605a2.477 2.477 0 10-3.503 3.503 2.477 2.477 0 003.503-3.503z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
      ) : (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M14.26 11.142c1.225 1.597 1.369 3.3-.03 4.7l-1.351 1.35c-.399 1.94-1.065 3.4-2.035 4.37-2.215 2.216-5.32 1.773-7.75-.656-2.43-2.43-2.872-5.535-.656-7.75.97-.97 2.43-1.636 4.37-2.035l1.35-1.351c1.4-1.399 3.103-1.255 4.7-.03l4.948-4.947a.99.99 0 010-1.401l2.102-2.102a.99.99 0 011.4 0l1.402 1.401a.99.99 0 010 1.401l-2.102 2.102a.99.99 0 01-1.4 0l-4.949 4.948zM7.591 12.98l-.116.03c-1.734.322-2.942.852-3.637 1.547-1.34 1.34-1.072 3.219.657 4.947 1.728 1.73 3.607 1.997 4.947.657.695-.695 1.225-1.903 1.548-3.637a.99.99 0 01.273-.52l1.565-1.564c.68-.68.494-1.52-.627-2.642-1.121-1.12-1.963-1.306-2.642-.627l-1.565 1.565a.99.99 0 01-.403.244zm-1.203 4.427a1.486 1.486 0 112.102-2.102 1.486 1.486 0 01-2.102 2.102z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
      )}
    </>
  )
}

export default MusiciansIcon

MusiciansIcon.propTypes = {
  props: PropTypes.string,
  active: PropTypes.bool,
}

MusiciansIcon.defaultProps = {
  props: null,
  active: false,
}
