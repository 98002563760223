import React from 'react'

import { Container, Navbar } from './styles'
import Footer from '../Footer'

import NavLinks from './NavLinks'
import { LogoIcon } from '~/assets/icons'

const Sidebar = () => {
  return (
    <Container>
      <LogoIcon size={50} />

      <Navbar>
        <NavLinks />
      </Navbar>
      <Footer auth />
    </Container>
  )
}

export default Sidebar
