import React from 'react'
import PropTypes from 'prop-types'

const ChevronIcon = ({ props, r, size = 24 }) => {
  return (
    <>
      {r ? (
        <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
          <path
            scale={1}
            d="M9.336 6.753a1 1 0 011.322-.006l.09.089 4 4.5a1 1 0 01.077 1.23l-.078.098-4 4.5a1 1 0 01-1.572-1.23l.078-.098L12.66 12 9.253 8.164a1 1 0 01-.006-1.322l.089-.09z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      ) : (
        <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
          <path
            scale={1}
            d="M13.253 6.836a1 1 0 011.572 1.23l-.078.098L11.338 12l3.41 3.836a1 1 0 01.005 1.322l-.089.09a1 1 0 01-1.322.005l-.09-.089-4-4.5a1 1 0 01-.077-1.23l.078-.098 4-4.5z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      )}
    </>
  )
}

export default ChevronIcon

ChevronIcon.propTypes = {
  props: PropTypes.string,
  r: PropTypes.bool,
  size: PropTypes.number,
}

ChevronIcon.defaultProps = {
  props: null,
  r: false,
  size: 24,
}
