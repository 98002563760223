import React from 'react'
import { withAnalytics } from '~/hocs/withAnalytics'

import { Container } from '~/components'

import { about } from '~/assets/copy'
import cover from '~/assets/images/about.jpg'
import { Section } from './styles'

function About() {
  return (
    <Container>
      <Section>
        <h1>{about.title}</h1>
        <div>
          <img src={cover} alt="" />
          <p>{about.text}</p>
        </div>
      </Section>
    </Container>
  )
}

export default withAnalytics(About, {})
