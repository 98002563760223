import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './styles'

const Block = ({
  block,
  padding,
  margin,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  flex,
  justify,
  align,
  direction,
  children,
  style,
}) => {
  return (
    <Container
      flex={flex}
      padding={padding}
      margin={margin}
      marginBottom={marginBottom}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      block={block}
      justify={justify}
      align={align}
      direction={direction}
      style={style}
    >
      {children}
    </Container>
  )
}

export default Block

Block.propTypes = {
  block: PropTypes.bool,
  flex: PropTypes.number,
  padding: PropTypes.number,
  margin: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  justify: PropTypes.string,
  align: PropTypes.string,
  direction: PropTypes.string,
  style: PropTypes.node,
  children: PropTypes.node.isRequired,
}

Block.defaultProps = {
  block: false,
  padding: 0,
  margin: 0,
  marginBottom: 0,
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  flex: 1,
  justify: null,
  align: null,
  direction: 'column',
  style: null,
}
