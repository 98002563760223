import styled from 'styled-components'
import { theme } from '~/constants'

export const Container = styled.footer`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  margin-bottom: 10px;

  small {
    color: ${theme.color.gray};
  }
`
