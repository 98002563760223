import React from 'react'
import { useField } from '@rocketseat/unform'
import api from '~/services/api'

import { ImageContainer, PreviewItem } from './styles'
import { Image } from '~/components'

export default function ImageInput({ name }) {
  const { fieldName, registerField, error } = useField(name)

  const [file, setFile] = React.useState()
  const [preview, setPreview] = React.useState()

  const ref = React.useRef()

  React.useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'dataset.file',
      })
    }
  }, []) // eslint-disable-line

  async function handleChange(e) {
    try {
      const data = new FormData()
      data.append('file', e.target.files[0])
      console.tron.log(data.size)

      // const response = await api.post('files', data)

      // const { id, url } = response.data

      // setFile(id)
      // setPreview(url)
    } catch (err) {
      console.tron.log(err)
    }
  }

  return (
    <ImageContainer>
      <label htmlFor={fieldName}>
        <PreviewItem>
          <Image src={preview} w={80} h={80} size={50} />
          <div>
            {preview ? (
              <>
                <p>Upload another File</p>
              </>
            ) : (
              <p>Select File</p>
            )}
          </div>
        </PreviewItem>
        <input
          type="file"
          id={fieldName}
          accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
        />
      </label>
      {error && <span>{error}</span>}
    </ImageContainer>
  )
}
