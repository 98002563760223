import React from 'react'
import PropTypes from 'prop-types'

import api from '~/services/api'

import { LikeIcon, ShareIcon, CommentIcon } from '~/assets/icons'
import { Button } from '~/components'
import { Container } from './styles'

const PostButtonsItem = ({ data }) => {
  const { id, comments, __meta__ } = data
  const likes = __meta__.likes_count

  async function likePost() {
    await api.post(`posts/${id}/likes`)
  }

  // async function likePost() {
  //   await api.post(`files/${id}/likes`)
  // }

  // async function likePost() {
  //   await api.post(`files/${id}/likes`)
  // }

  return (
    <Container>
      <Button preset="icon" onClick={likePost}>
        <LikeIcon />
        <p>{likes}</p>
      </Button>
      <Button preset="icon">
        <CommentIcon />
        <p>{comments.length}</p>
      </Button>
      <Button preset="icon">
        <ShareIcon />
        <p>Share</p>
      </Button>
    </Container>
  )
}

export default PostButtonsItem

PostButtonsItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    __meta__: PropTypes.shape({
      likes_count: PropTypes.string,
    }),
    comments: PropTypes.array,
  }).isRequired,
}
